import { Card, Button, CardBody, Input } from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import React, { useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packageItem, setPackageItem] = useState({
    packageNameTr: "",
    packageNameDe: "",
    packageNameFr: "",
    packageNameIt: "",
    packageNameNl: "",
    packageNameEn: "",
    packageDescription: "",
    packageMontly: "",
    package3Montly: "",
    package6Montly: "",
    package12Montly: "",
    package24Montly: "",
    packageSerial: "",
    packageType: "0",
    packageRegion: currentLanguage,
  });

  const [images, setImages] = useState([]);

  const savePackage = async () => {
    setLoading(true);

    try {
      const uploadImagesResponse = await uploadImages();
      if (uploadImagesResponse.length > 0) {
        const payload = {
          ...packageItem,
          packageBgIds: uploadImagesResponse.join(","),
        };
        const response = await fetch(
          `${API_URL}:${API_PORT}/package/savePackage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) {
          myToast({ status: "success", message: texts.packageSaveSuccess });
          navigate("/packages");
        } else {
          myToast({ status: "error", message: texts.packageSaveError });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click(); // Butona tıklandığında dosya seçme inputunu tetikletiyoruz
  };

  const checkAspectRatio = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const width = this.width;
          const height = this.height;
          const aspectRatio = height / width;

          if (aspectRatio >= 1.41 && aspectRatio < 1.42) {
            resolve(true); // Resmin oranı 1.41:1 ve 1.42:1 arasında
          } else {
            resolve(false); // Resmin oranı bu aralıkta değil
          }
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files; // Yüklenen dosyaları alın
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Uzantı JPEG veya PNG değilse, işlemi durdur
        myToast({ status: "error", message: texts.fileExtensionError });
        continue;
      }

      // Resmin oranını kontrol etme
      const isCorrectAspectRatio = await checkAspectRatio(file);
      if (!isCorrectAspectRatio) {
        myToast({ status: "error", message: texts.fileAspectRatioError });
        continue;
      }

      // Resmi base64'e çevirme işlemi
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageData = reader.result; // Resmi base64 olarak alın
        newImages.push({ file, imageData }); // Yeni resimleri listeye ekleyin
        if (newImages.length === files.length) {
          // Tüm dosyalar işlendiğinde state'i güncelle
          setImages((prevImages) => [...prevImages, ...newImages]);
        }
      };
    }
  };
  const handleInputChange = (e, key) => {
    let value = e.target.value;

    // Virgülü nokta ile değiştir
    value = value.replace(",", ".");

    // Sayı olup olmadığını kontrol et
    if (!isNaN(value)) {
      setPackageItem({
        ...packageItem,
        [key]: value,
      });
    }
  };
  const uploadImages = async () => {
    try {
      const newImageIds = [];

      for (const image of images) {
        const formData = new FormData();
        formData.append("file", image.file); // image.file olmalı
        const imageType = "backgroundimages";
        const response = await fetch(
          `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          newImageIds.push(data.imageId); // Assuming `data.imageId` is the correct field
          myToast({ status: "success", message: texts.imageUploadSuccess });
        } else {
          myToast({ status: "error", message: texts.imageUploadError });
        }
      }

      return newImageIds;
    } catch (error) {
      myToast({ status: "error", message: texts.imageUploadError });
      console.error(error);
      return [];
    }
  };

  const updatePackage = async () => {
    try {
    } catch (error) {
      myToast({ status: "error", message: texts.dealerUpdateError });
    }
  };

  const handleSave = async () => {
    if (packageItem.packageId) {
      await updatePackage();
    } else {
      await savePackage();
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <Spinner className="h-40 w-40" />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <h>{texts.addDealer}</h>
          <CardBody className="px-0 flex flex-col gap-2">
            <Input
              label={texts.packagesTableTitle.packageNameDe}
              value={packageItem.packageNameDe}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameDe: e.target.value,
                });
              }}
            />
            <Input
              label={texts.packagesTableTitle.packageNameEn}
              value={packageItem.packageNameEn}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameEn: e.target.value,
                  packageSerial: e.target.value,
                });
              }}
            />
            <Input
              label={texts.packagesTableTitle.packageNameTr}
              value={packageItem.packageNameTr}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameTr: e.target.value,
                });
              }}
            />
            <Input
              label={texts.packagesTableTitle.packageNameFr}
              value={packageItem.packageNameFr}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameFr: e.target.value,
                });
              }}
            />
            <Input
              label={texts.packagesTableTitle.packageNameIt}
              value={packageItem.packageNameIt}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameIt: e.target.value,
                });
              }}
            />
            <Input
              label={texts.packagesTableTitle.packageNameNl}
              value={packageItem.packageNameNl}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameNl: e.target.value,
                });
              }}
            />
            <Input
              label={texts.packagesTableTitle.packageDescription}
              value={packageItem.packageDescription}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageDescription: e.target.value,
                });
              }}
            />

            <Input
              label={texts.packagesTableTitle.package3Monthly}
              value={packageItem.package3Montly}
              onChange={(e) => handleInputChange(e, "package3Montly")}
            />
            <Input
              label={texts.packagesTableTitle.package6Monthly}
              value={packageItem.package6Montly}
              onChange={(e) => handleInputChange(e, "package6Montly")}
            />
            <Input
              label={texts.packagesTableTitle.packageYearly}
              value={packageItem.package12Montly}
              onChange={(e) => handleInputChange(e, "package12Montly")}
            />

            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageType: e.target.value,
                })
              }
            >
              <option value="0">{texts.packageType0}</option>
              <option value="1">{texts.packageType1}</option>
              <option value="2">{texts.packageType2}</option>
            </select>

            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageRegion: e.target.value,
                })
              }
            >
              {Object.keys(languages).map((langKey) => (
                <option key={langKey} value={langKey}>
                  {langKey}
                </option>
              ))}
            </select>
            <div className="w-full flex items-start justify-start">
              <input
                type="file"
                id="file-upload"
                accept="image/jpeg, image/png, image/jpg, image/heic" // Sadece resim dosyalarını seçebilmesi için
                ref={fileInputRef} // useRef ile oluşturulan referans burada atanıyor
                onChange={handleFileUpload}
                style={{ display: "none" }}
                multiple // Birden fazla dosya seçimine izin ver
              />
              <label
                htmlFor="file-upload"
                className="font-[Inter] flex items-center justify-center cursor-pointer"
                onClick={triggerFileInput} // Butona tıklandığında triggerFileInput fonksiyonu çağrılıyor
              >
                <Button className=" font-[Inter]" color="blue" size="md">
                  {texts.addImage}
                </Button>
              </label>
            </div>
            <div className="flex flex-row w-full gap-4">
              {Array.isArray(images) &&
                images.map((image, index) => (
                  <div
                    key={index}
                    className="relative group flex justify-center items-center"
                  >
                    <img
                      className="h-[105px] w-[75px] object-cover rounded-md"
                      src={image.imageData}
                      alt={`Image_${index + 1}`}
                    />
                    <button
                      onClick={() => {
                        const newImages = images.filter(
                          (img, i) => i !== index
                        );
                        setImages(newImages);
                      }}
                      className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    >
                      <TrashIcon className="h-5 w-5 text-red-300 hover:text-red-700" />
                    </button>
                  </div>
                ))}
            </div>

            <Button
              color="blue"
              className="w-full font-[Inter]"
              onClick={handleSave}
            >
              {packageItem.packageId ? texts.updatePackage : texts.savePackage}
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Index;
