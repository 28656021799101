import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  CardHeader,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../components/myToast";
import { useLanguage } from "../../components/languageProvider";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].dealerTexts;
  const [dealers, setDealers] = useState([]);
  const size = 10;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDealerModal, setDeleteDealerModal] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();
  const TABLE_HEAD = [
    "",
    texts.dealerTableTitle.dealerName,
    texts.dealerTableTitle.dealerPhone,
    texts.dealerTableTitle.dealerAddress,
    texts.dealerPostalCode,
    "",
  ];

  const getAllDealer = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/dealer/getAllDealer?page=${page}&size=${size}`
      );
      const data = await response.json();
      setTotalPages(data?.totalPages);
      setDealers(data?.content);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllDealer();
  }, [page]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleOpenDeleteDealerModal = (dealer) => {
    setSelectedDealer(dealer);
    setDeleteDealerModal(true);
  };
  const handleDeleteDealer = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/dealer/deleteDealer?dealerId=${selectedDealer.dealerId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      if (response.status === 200) {
        myToast({
          status: "success",
          message: texts.deleteDealerSuccess,
        });
        getAllDealer();
      } else {
        myToast({
          status: "error",
          message: texts.deleteDealerError,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteDealerModal(false);
    setDeleteLoading(false);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <span className="px-4 py-2 text-[Inter] text-xl ">{texts.users}</span>

        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none py-4"
        >
          <div className="flex flex-col justify-between md:flex-row md:items-center gap-4">
            <Button
              className="w-60 flex items-center font-[Inter] justify-center"
              color="blue"
              size="sm"
              onClick={() => {
                navigate("/dealer/add-dealer");
              }}
            >
              <PlusCircleIcon className="h-5 w-5 mr-2" />
              {texts.addDealer}
            </Button>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dealers.map((item, index) => {
                    const isLast = index === dealers.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.dealerId}>
                        <td
                          className={
                            isLast
                              ? "p-4 font-bold font-[Inter] pl-12"
                              : "p-4 border-b pl-12 border-blue-gray-50 font-bold font-[Inter]"
                          }
                        >
                          <Avatar
                            src="https://www.svgrepo.com/show/527630/buildings-3.svg"
                            size="sm"
                          />
                        </td>
                        <td className={classes}>
                          <span>{item.dealerName}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.dealerPhone}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.dealerAddress}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.dealerPostalCode}</span>
                        </td>

                        <td className={classes}>
                          <Tooltip content={texts.editDealer}>
                            <IconButton
                              variant="text"
                              onClick={() => {
                                navigate(`/dealer/add-dealer/${item.dealerId}`);
                              }}
                            >
                              <PencilIcon className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content={texts.deleteDealer}>
                            <IconButton
                              variant="text"
                              onClick={() => {
                                handleOpenDeleteDealerModal(item);
                              }}
                            >
                              <TrashIcon className="h-4 w-4 text-red-500" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
      </Card>
      <Dialog
        open={deleteDealerModal}
        onClose={() => setDeleteDealerModal(false)}
      >
        <DialogBody>
          <span className="text-[Inter] text-lg">
            {texts.deleteDealerMessage}
          </span>
        </DialogBody>
        <DialogFooter>
          <Button
            color="blue"
            className="font-[Inter] mr-2"
            onClick={() => setDeleteDealerModal(false)}
          >
            {texts.no}
          </Button>
          <Button
            color="red"
            className="font-[Inter]"
            loading={deleteLoading}
            onClick={handleDeleteDealer}
          >
            {texts.yes}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Index;
