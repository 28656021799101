import {
  EyeIcon,
  PlusCircleIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import moment from "moment";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../components/myToast";
import { useLanguage } from "../../components/languageProvider";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const size = 4;
  const texts = languages[currentLanguage];
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [params, setParams] = useState(null);

  const navigate = useNavigate();

  const getAllUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/getAllUserWithBgPage?page=${page}&size=${size}&param=${
          params !== null && params !== "" ? params : ""
        }&createdAt=desc`
      );
      const data = await response.json();
      setTotalPages(data?.totalPages);
      setUsers(data?.content);
    } catch (error) {
      myToast({
        status: "error",
        message: "An error occurred while fetching data",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllUser();
  }, [page, params]);

  const TABLE_HEAD = [
    "",

    texts.userTableTitle.name + " " + texts.userTableTitle.surname,
    texts.userTableTitle.companyNumber,
    texts.userTableTitle.userName,
    texts.userTableTitle.companyName,
    texts.userTableTitle.phone,
    texts.userTableTitle.freeTrial,
    texts.userTableTitle.userRegistered,
    "",
  ];
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleOpenDeleteUserModal = (user) => {
    setSelectedUser(user);
    setUserDeleteModal(true);
  };

  const handleShowImages = (user) => {
    setSelectedUser(user);
    setShowImagesModal(true);
  };

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/deleteUser?userId=${selectedUser?.userId}`,
        {
          method: "DELETE",
        }
      );
      if (response.status === 200) {
        myToast({
          status: "success",
          message: texts.saveStatus.deleteUserSuccess,
        });
        getAllUser();
      } else {
        myToast({
          status: "error",
          message: texts.saveStatus.deleteUserError,
        });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.userDeleteError });
    }
    setDeleteLoading(false);
    setUserDeleteModal(false);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <span className="px-4 py-2 text-[Inter] text-xl ">{texts.users}</span>

        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none py-4"
        >
          <div className="flex  flex-wrap justify-between md:flex-row md:items-center gap-4">
            <input
              type="text"
              placeholder={texts.search}
              className=" h-10 border shadow-md border-blue-gray-100 rounded-md p-2 w-full lg:w-[83%] font-[Inter] text-sm"
              onChange={(e) => setParams(e.target.value)}
            />

            <Button
              className="lg:w-[15%] w-full flex items-center font-[Inter] justify-center"
              color="blue"
              size="sm"
              onClick={() => {
                navigate("/users/add-user");
              }}
            >
              <PlusCircleIcon className="h-5 w-5 mr-2" />
              {texts.userTableTitle.addUser}
            </Button>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {users.map((item, index) => {
                    const isLast = index === users.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.productId}>
                        <td className={classes}>
                          {item?.userImagePath ? (
                            <Avatar
                              src={
                                API_URL +
                                ":" +
                                API_PORT +
                                "/" +
                                item?.userImagePath
                              }
                              alt={item.userName}
                              size="lg"
                              variant="circular"
                              className="border font-[Inter] border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1 mr-1"
                            />
                          ) : (
                            <Avatar
                              src={
                                "https://www.svgrepo.com/show/474987/shop.svg"
                              }
                              alt={item.userName}
                              size="lg"
                              variant="circular"
                              className="border font-[Inter] border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1 mr-1"
                            />
                          )}
                        </td>
                        <td className={classes}>
                          <span>
                            {item?.userName + " " + item?.userSurname} <br />
                            {item?.userFirmName}
                          </span>
                        </td>
                        <td className={classes}>
                          <span>{item?.customerNumber}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.userUsername}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.userFirmName}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.userPhone}</span>
                        </td>

                        <td className={classes}>
                          {item.userTrial ? (
                            <span className="text-green-500 font-[Inter]">
                              {texts.yes}
                            </span>
                          ) : (
                            <span className="text-red-500 font-[Inter]">
                              {texts.no}
                            </span>
                          )}
                        </td>
                        <td className={classes}>
                          <span>
                            {moment(item.userRegistrationDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </td>
                        <td className={classes}>
                          <Tooltip content={texts.showImages}>
                            <IconButton
                              variant="text"
                              onClick={() => handleShowImages(item)}
                            >
                              <EyeIcon className="h-6 w-6 text-blue-500" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content={texts.userTableTitle.editUser}>
                            <IconButton
                              variant="text"
                              onClick={() => {
                                navigate(`/users/edit-user/${item.userId}`);
                              }}
                            >
                              <PencilIcon className="h-6 w-6" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content={texts.userTableTitle.deleteUser}>
                            <IconButton
                              variant="text"
                              onClick={() => {
                                handleOpenDeleteUserModal(item);
                              }}
                            >
                              <TrashIcon className="h-6 w-6 text-red-600" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
        <Dialog
          open={userDeleteModal}
          onClose={() => setUserDeleteModal(false)}
        >
          <DialogHeader>
            <span className="text-[Inter] text-xl">
              {selectedUser?.userName}
            </span>
          </DialogHeader>
          <DialogBody>
            <span className="text-[Inter] text-lg">
              {texts.saveStatus.deleteUserMessage}
            </span>
          </DialogBody>
          <DialogFooter>
            <Button
              color="blue"
              className="font-[Inter] mr-2"
              onClick={() => setUserDeleteModal(false)}
            >
              {texts.no}
            </Button>
            <Button
              color="red"
              loading={deleteLoading}
              className="font-[Inter]"
              onClick={() => {
                handleDeleteUser();
              }}
            >
              {texts.yes}
            </Button>
          </DialogFooter>
        </Dialog>
        <Dialog
          open={showImagesModal}
          onClose={() => setShowImagesModal(false)}
          size="lg"
        >
          <DialogHeader className="font-[Inter]">
            {texts.userModalTable.userDetails}
          </DialogHeader>
          <DialogBody>
            <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
              <TabsHeader>
                <Tab className="font-[Inter]" value="1">
                  {texts.userModalTable.userInformation}
                </Tab>
                <Tab className="font-[Inter]" value="2">
                  {texts.userModalTable.userBackgrondImages}
                </Tab>
              </TabsHeader>
              <TabsBody>
                <TabPanel value="1">
                  <div className="overflow-y-auto max-h-96 p-4 bg-gray-100 rounded-md shadow-lg font-[Inter]">
                    <h2 className="text-xl font-bold mb-4">
                      {texts.userModalTable.userInformation}
                    </h2>
                    <div className="space-y-2">
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.id} :</strong>{" "}
                        {selectedUser?.userId}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userTableTitle.companyNumber} :</strong>{" "}
                        {selectedUser?.customerNumber}
                      </p>
                      <p className="text-gray-700">
                        <strong> {texts.userModalTable.name} :</strong>{" "}
                        {selectedUser?.userName}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.surname} :</strong>{" "}
                        {selectedUser?.userSurname}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.userName} :</strong>{" "}
                        {selectedUser?.userUsername}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.password} :</strong>{" "}
                        {selectedUser?.userPassword}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.role} :</strong>{" "}
                        {selectedUser?.userRole}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.registerDate} :</strong>{" "}
                        {moment(selectedUser?.userRegistrationDate).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.freeTrial} :</strong>
                        {selectedUser?.userTrial
                          ? texts.userModalTable.freeTrialYes
                          : texts.userModalTable.freeTrialNo}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.email} :</strong>{" "}
                        {selectedUser?.userEmail}
                      </p>
                      <p className="text-gray-700">
                        <strong> {texts.userModalTable.phone} :</strong>{" "}
                        {selectedUser?.userPhone}
                      </p>
                      <p className="text-gray-700">
                        <strong> {texts.userModalTable.companyName} :</strong>{" "}
                        {selectedUser?.userFirmName}
                      </p>
                      <p className="text-gray-700">
                        <strong> {texts.userModalTable.userTaxNumber} :</strong>{" "}
                        {selectedUser?.userTaxNumber}
                      </p>
                    </div>
                    <h3 className="text-lg font-semibold mt-6 mb-4">
                      {texts.userModalTable.userBankInfo}
                    </h3>
                    <div className="space-y-2">
                      <p className="text-gray-700">
                        <strong>
                          {" "}
                          {texts.userModalTable.userAccountNumber} :
                        </strong>{" "}
                        {selectedUser?.bankAccount?.accountNumber}
                      </p>
                      <p className="text-gray-700">
                        <strong> {texts.userModalTable.userBankName} :</strong>{" "}
                        {selectedUser?.bankAccount?.bankName}
                      </p>
                      <p className="text-gray-700">
                        <strong> {texts.userModalTable.userIBAN} :</strong>{" "}
                        {selectedUser?.bankAccount?.iban}
                      </p>
                    </div>
                    <h3 className="text-lg font-semibold mt-6 mb-4">
                      {texts.userModalTable.dealerInfo}
                    </h3>
                    <div className="space-y-2">
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.dealerName} :</strong>{" "}
                        {selectedUser?.dealer?.dealerName}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.dealerAddress} :</strong>{" "}
                        {selectedUser?.dealer?.dealerAddress}
                      </p>
                      <p className="text-gray-700">
                        <strong>{texts.userModalTable.dealerPhone} :</strong>{" "}
                        {selectedUser?.dealer?.dealerPhone}
                      </p>
                      <p className="text-gray-700">
                        <strong>
                          {texts.userModalTable.dealerPostalCode} :
                        </strong>{" "}
                        {selectedUser?.dealer?.dealerPostalCode}
                      </p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className="flex flex-wrap overflow-y-auto h-96 justify-start gap-4 w-full">
                    {selectedUser?.selectedImages.map((image, index) => (
                      <img
                        key={index}
                        src={`${API_URL}:${API_PORT}/${image.imageName}`}
                        alt={image.imageName}
                        className="h-2/3 object-cover"
                      />
                    ))}
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          </DialogBody>
          <DialogFooter>
            <Button
              color="blue"
              className="font-[Inter]"
              onClick={() => setShowImagesModal(false)}
            >
              {texts.close}
            </Button>
          </DialogFooter>
        </Dialog>
      </Card>
    </>
  );
};

export default Index;
