import React, { useState } from "react";
import Leftmenu from "../components/Leftmenu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import routes from "../routes";
import Login from "./login";
import { LanguageProvider } from "../components/languageProvider";
import { UserRoleProvider } from "../components/UserRoleProvider";
import CanViewPage from "../components/CanViewPage";

const Main = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  return (
    <UserRoleProvider>
      <LanguageProvider>
        <Router>
          <div className="w-full h-full px-4 py-2 flex flex-col justify-start gap-4">
            {userLoggedIn ? (
              <>
                <Leftmenu />
                <div className="w-full font-[Inter] flex flex-col md:flex-row justify-center items-center">
                  <Routes>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={
                          <CanViewPage canview={route.canview}>
                            {route.element}
                          </CanViewPage>
                        }
                      />
                    ))}
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                  </Routes>
                </div>
              </>
            ) : (
              <Routes>
                <Route
                  path="/login"
                  element={<Login setUserLoggedIn={setUserLoggedIn} />}
                />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            )}
          </div>
        </Router>
      </LanguageProvider>
    </UserRoleProvider>
  );
};

export default Main;
