import { TrashIcon, EyeIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../components/languageProvider";
import Pagination from "../../components/Pagination";
import EmptyImage from "../../assets/images/emptyProduct.svg";
import myToast from "../../../src/components/myToast";

const CategoryName = ({ item, currentLanguage }) => {
  const categoryName = {
    tr: item.category.categoryNameTr,
    de: item.category.categoryNameDe,
    fr: item.category.categoryNameFr,
    it: item.category.categoryNameIt,
    nl: item.category.categoryNameNl,
    en: item.category.categoryNameEn,
  };

  return (
    <span color="blue-gray" className="font-bold font-[Inter]">
      {categoryName[currentLanguage] || categoryName.en}
    </span>
  );
};
const SubCategoryName = ({ item, currentLanguage }) => {
  const subCategoryName = {
    tr: item.subCategoryNameTr,
    de: item.subCategoryNameDe,
    fr: item.subCategoryNameFr,
    it: item.subCategoryNameIt,
    nl: item.subCategoryNameNl,
    en: item.subCategoryNameEn,
  };

  return (
    <span color="blue-gray" className="font-bold font-[Inter]">
      {subCategoryName[currentLanguage] || subCategoryName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const size = 5;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [selectedProductSubCategories, setSelectedProductSubCategories] =
    useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getAllProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ProductSubCategoryRelation/getAllProductSubCategoryRelation?page=${page}&size=${size}&sort=ps.product.createdAt,desc`
      );
      const data = await response.json();

      setProducts(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllProducts();
  }, [page]);

  const handleShowSubCategories = (item) => {
    setShowSubCategories(true);
    setSelectedProductSubCategories(item?.subCategories);
  };
  const handleHideSubCategories = () => {
    setShowSubCategories(false);
  };

  const handleShowImageDialog = (imagePath) => {
    setSelectedImage(`${API_URL}:${API_PORT}/${imagePath}`);
    setShowImageDialog(true);
  };

  const handleHideImageDialog = () => {
    setShowImageDialog(false);
  };

  const handleShowDeleteDialog = (item) => {
    setDeleteProductModal(true);
    setSelectedProduct(item);
  };
  const handleHideDeleteDialog = () => {
    setDeleteProductModal(false);
  };

  const handleDeleteProduct = (item) => async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ProductSubCategoryRelation/deleteProduct?productId=${item?.product?.productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      if (response.status === 200) {
        myToast({ status: "success", message: texts.productDeleteSuccess });
        setDeleteProductModal(false);
        getAllProducts();
      } else {
        myToast({ status: "error", message: texts.productDeleteError });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
  };
  const TABLE_HEAD = [
    texts.productsTableTitle.productImage,
    texts.productsTableTitle.productUser,
    texts.productsTableTitle.category,
    "",
  ];
  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <span className="px-4 py-4 text-[Inter] text-xl ">
          {texts.products}
        </span>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span
                          variant="small"
                          color="blue-gray"
                          className=" font-normal font-[Inter] leading-none opacity-70"
                        >
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {products.map((item, index) => {
                    const isLast = index === products.length - 1;
                    const classes = isLast
                      ? "py-2 px-4 font-[Inter]"
                      : "py-2 px-4 border-b font-[Inter] border-blue-gray-50";

                    return (
                      <tr key={item.productId}>
                        <td className={classes}>
                          <div className="relative flex items-center">
                            <img
                              src={
                                item?.product?.productImagePath
                                  ? `${API_URL}:${API_PORT}/${item?.product?.productImagePath}`
                                  : EmptyImage
                              }
                              alt={item?.product?.productName}
                              className="border font-[Inter] h-[105px] w-[70px] object-cover border-blue-gray-50 bg-blue-gray-50/50"
                            />
                          </div>
                        </td>
                        <td className={classes}>
                          <span className="font-bold font-[Inter]">
                            {item?.product?.user?.userName +
                              " " +
                              item?.product?.user?.userSurname}
                            <br />
                            {item?.product?.user?.userFirmName}
                          </span>
                        </td>
                        <td className={classes}>
                          <Button
                            color="yellow"
                            onClick={() => handleShowSubCategories(item)}
                            size="sm"
                            rounded={true}
                            className="font-[Inter] text-md"
                          >
                            {texts.showCategories}
                          </Button>
                        </td>
                        <td className={classes}>
                          <Tooltip content={texts.deleteProduct}>
                            <IconButton
                              variant="text"
                              className="text-red-600"
                              onClick={() => handleShowDeleteDialog(item)}
                            >
                              <TrashIcon className="h-6 w-6" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content={texts.showProductImage}>
                            <IconButton
                              variant="text"
                              onClick={() =>
                                item?.product?.productImagePath
                                  ? handleShowImageDialog(
                                      item?.product?.productImagePath
                                    )
                                  : myToast({
                                      status: "error",
                                      message: texts.noImageFound,
                                    })
                              }
                            >
                              <EyeIcon className="h-6 w-6" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
      </Card>
      <Dialog
        size="lg"
        open={showSubCategories}
        onClose={handleHideSubCategories}
      >
        <DialogBody>
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className=" justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <span
                    variant="small"
                    color="blue-gray"
                    className=" font-normal font-[Inter] leading-none opacity-70"
                  >
                    {texts.categories}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedProductSubCategories?.map((item, index) => {
                const isLast =
                  index === selectedProductSubCategories.length - 1;
                const classes = isLast
                  ? "p-4 font-[Inter]"
                  : "p-4 border-b font-[Inter] border-blue-gray-50";

                return (
                  <tr key={item.productSubCategoryId}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <span className="font-bold font-[Inter]">
                          <SubCategoryName
                            item={item}
                            currentLanguage={currentLanguage}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            onClick={handleHideSubCategories}
            ripple="dark"
            className="font-[Inter]"
          >
            {texts.close}
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        className="w-full bg-transparent shadow-none"
        open={showImageDialog}
        onClose={handleHideImageDialog}
      >
        <div className=" flex flex-col md:gap-0 gap-2 items-center justify-center ">
          {selectedImage && (
            <>
              <div className="flex lg:w-3/4 w-full justify-end">
                <button
                  onClick={handleHideImageDialog}
                  className="z-10  bg-white rounded-full  text-red-600 transition-all duration-300 hover:bg-red-700 hover:text-white"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="w-[400px] h-auto rounded-3xl">
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full h-auto"
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
      <Dialog open={deleteProductModal} onClose={handleHideDeleteDialog}>
        <DialogBody>
          <span className="font-[Inter]">{texts.productDeleteInfo}</span>
        </DialogBody>
        <DialogFooter>
          <Button
            color="blue"
            onClick={handleHideDeleteDialog}
            ripple="dark"
            className="font-[Inter] mr-2"
          >
            {texts.productCancel}
          </Button>
          <Button
            color="red"
            loading={deleteLoading}
            onClick={handleDeleteProduct(selectedProduct)}
            ripple="dark"
            className="font-[Inter]"
          >
            {texts.productDelete}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Index;
