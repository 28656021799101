import React from "react";
import DasboardItem from "../../components/DasboardItem";
import {
  ArchiveBoxIcon,
  ListBulletIcon,
  RectangleStackIcon,
  UserGroupIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  ChatBubbleLeftRightIcon,
  PhotoIcon,
  ArrowUpOnSquareStackIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { languages } from "../../constants/languages/languages";
import bgLogo from "../../assets/images/backgroundLogo.svg";
import CanViewItem from "../../components/CanViewItem";
import { useLanguage } from "../../components/languageProvider";
import { Select, Option } from "@material-tailwind/react";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const countries = [
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "fr",
      name: "French",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/fr.svg",
      },
    },
    {
      id: "it",
      name: "Italian",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/it.svg",
      },
    },
    {
      id: "nl",
      name: "Dutch",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/nl.svg",
      },
    },
  ];
  const texts = languages[currentLanguage];
  const dashbordItems = [
    {
      title: texts.products,
      link: "/products",
      icon: <ArchiveBoxIcon className="w-12 h-12" />,
      canview: "superadmin",
    },
    {
      title: texts.categories,
      link: "/category",
      icon: <ListBulletIcon className="w-12 h-12" />,
      canview: "superadmin",
    },
    {
      title: texts.sendedCategoryImages,
      link: "/category/sendedCategoryImages",
      icon: <ArrowUpOnSquareStackIcon className="w-12 h-12" />,
      canview: "superadmin",
    },
    {
      title: texts.users,
      link: "/users",
      icon: <UserGroupIcon className="w-12 h-12" />,
      canview: "admin",
    },
    {
      title: texts.payments,
      link: "/payments",
      icon: <BanknotesIcon className="w-12 h-12" />,
      canview: "superadmin",
    },

    {
      title: texts.dealer,
      link: "/dealer",
      icon: <BuildingOffice2Icon className="w-12 h-12" />,
      canview: "superadmin",
    },
    /*{
      title: texts.supportRequests,
      link: "/supportrequest",
      icon: <ChatBubbleLeftRightIcon className="w-12 h-12" />,
      canview: "admin",
    },*/
    {
      title: texts.userBackgrondImages,
      link: "/background-images",
      icon: <PhotoIcon className="w-12 h-12" />,
      canview: "admin",
    },
    {
      title: texts.packages,
      link: "/packages",
      icon: <RectangleStackIcon className="w-12 h-12" />,
      canview: "superadmin",
    },
    {
      title: texts.printerPage,
      link: "/print",
      icon: <PrinterIcon className="w-12 h-12" />,
      canview: "superadmin",
    },
  ];

  return (
    <>
      <div className="relative w-full h-full mx-4 rounded-2xl px-12 py-16 flex items-start justify-start gap-4 flex-col">
        <div className="absolute inset-0 pt-36 flex items-center justify-center z-[-1]">
          <img
            src={bgLogo}
            alt="Background"
            className="w-2/3 h-auto object-cover opacity-30 rounded-2xl"
          />
        </div>
        <div className="relative z-10">
          <span className="text-[Inter] pl-4 text-3xl">
            {texts.welcomeToDashboard + ", FLYING!"}
          </span>
        </div>
        <div className="px-2">
          <Select
            size="lg"
            variant="outlined"
            className="flex w-full font-[Inter]"
            value={currentLanguage}
            label={texts.selectLanguages}
            onChange={(e) => changeLanguage(e)}
            selected={(element) =>
              element &&
              React.cloneElement(element, {
                disabled: true,
                className:
                  "flex items-center  opacity-100 px-0 gap-2 pointer-events-none",
              })
            }
          >
            {countries.map(({ name, flags, id }) => (
              <Option key={id} value={id} className="flex items-center gap-2">
                <img
                  src={flags.svg}
                  alt={name}
                  className="h-5 w-5 rounded-full object-cover"
                />
                {name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-row flex-wrap gap-9 justify-start items-center relative z-10">
          {dashbordItems.map((item, index) => (
            <CanViewItem key={index} canview={item.canview}>
              <DasboardItem
                title={item.title}
                link={item.link}
                icon={item.icon}
              />
            </CanViewItem>
          ))}
        </div>
      </div>
    </>
  );
};

export default Index;
