import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  IconButton,
  Tooltip,
  Spinner,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../components/languageProvider";
import { ArrowDownCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import EmptyImage from "../../assets/images/emptyProduct.svg";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/tr";

const PriceName = ({ item, currentLanguage }) => {
  const printNames = {
    tr: item.printNameTr,
    de: item.printNameDe,
    fr: item.printNameFr,
    it: item.printNameIt,
    nl: item.printNameNl,
    en: item.printNameEn,
  };

  return (
    <span className="font-bold font-[Inter]">
      {printNames[currentLanguage] || printNames.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  moment.locale(currentLanguage);
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [printRequests, setPrintRequests] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllPrintRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/printRequest/getAllPrintRequest?page=${page}&size=${size}`
      );
      const data = await response.json();
      setPrintRequests(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllPrintRequest();
  }, [page]);

  const TABLE_HEAD = [
    texts.printRequestTableTitle.printImage,
    texts.printRequestTableTitle.printUser,
    texts.printRequestTableTitle.printName,
    texts.printRequestTableTitle.printPrice,
    texts.printRequestTableTitle.printStatus,
    texts.printRequestTableTitle.printPaymentStatus,
    texts.printRequestTableTitle.printRequestDate,
    "",
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleDownloadPrintImage = (imageName) => {
    fetch(`${API_URL}:${API_PORT}/${imageName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imageName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none py-4 flex flex-row justify-between"
        >
          <span className="px-4 py-4 text-[Inter] text-xl ">
            {texts.printerPage}
          </span>
          <div className="flex items-center">
            <Button
              onClick={() => navigate("/print/review-prices")}
              color="blue"
              className="w-60 flex items-center font-[Inter] justify-center"
              size="sm"
            >
              {texts.printRequestTableTitle.viewPrices}
            </Button>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {printRequests.map((item, index) => {
                    const isLast = index === printRequests.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.id}>
                        <td className={classes}>
                          <div className="relative flex items-center">
                            <img
                              src={
                                item?.product?.productImagePath
                                  ? `${API_URL}:${API_PORT}/${item?.product?.productImagePath}`
                                  : EmptyImage
                              }
                              alt={item?.product?.productName}
                              className="border font-[Inter] h-[105px] w-[70px] object-contain border-blue-gray-50 bg-blue-gray-50/50"
                            />
                          </div>
                        </td>
                        <td className={classes}>
                          <span>{item?.user?.userUsername}</span>
                        </td>
                        <td className={classes}>
                          <PriceName
                            item={item?.print}
                            currentLanguage={currentLanguage}
                          />
                        </td>

                        <td className={classes}>
                          <span>{item?.print?.printPrice} €</span>
                        </td>
                        <td className={classes}>
                          <span>
                            {item?.requestStatus
                              ? texts.printRequestTableTitle
                                  .printStatusCompleted
                              : texts.printRequestTableTitle.printStatusPending}
                          </span>
                        </td>
                        <td className={classes}>
                          {item?.paymentStatus ? (
                            <span className="text-green-500">
                              {
                                texts.printRequestTableTitle
                                  .printPaymentStatusPaid
                              }
                            </span>
                          ) : (
                            <span className="text-red-500">
                              {
                                texts.printRequestTableTitle
                                  .printPaymentStatusUnpaid
                              }
                            </span>
                          )}
                        </td>
                        <td className={classes}>
                          <span>{moment(item?.requestDate).format("LL")}</span>
                        </td>

                        <td className={classes}>
                          <IconButton
                            variant="text"
                            onClick={() =>
                              handleDownloadPrintImage(
                                item?.product?.productImagePath
                              )
                            }
                          >
                            <ArrowDownCircleIcon className="h-8 w-8" />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
