import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  IconButton,
  Tooltip,
  Spinner,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../components/languageProvider";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { TrashIcon, PencilIcon, EyeIcon } from "@heroicons/react/24/outline";

const PackageName = ({ item, currentLanguage }) => {
  const packageName = {
    tr: item.packageNameTr,
    de: item.packageNameDe,
    fr: item.packageNameFr,
    it: item.packageNameIt,
    nl: item.packageNameNl,
    en: item.packageNameEn,
  };

  return (
    <span className="font-bold font-[Inter]">
      {packageName[currentLanguage] || packageName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePackageModal, setDeletePackageModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const getAllPackages = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBg?page=${page}&size=${size}`
      );
      const data = await response.json();
      setPackages(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllPackages();
  }, []);

  const TABLE_HEAD = [
    texts.packagesTableTitle.packageName,
    texts.packagesTableTitle.packageDescription,
    texts.packagesTableTitle.packageRegion,
    texts.packagesTableTitle.package3Monthly,
    texts.packagesTableTitle.package6Monthly,
    texts.packagesTableTitle.packageYearly,
    "",
    "",
  ];
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleOpenDeletePackageModal = (item) => {
    setSelectedPackage(item);
    setDeletePackageModal(true);
  };

  const handleShowImages = (item) => {
    setSelectedPackage(item);
    setShowImagesModal(true);
  };

  const handleDeletePackage = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/deletePackage?packageId=${selectedPackage.packageId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        getAllPackages();
        setDeletePackageModal(false);
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <span className="px-4 py-4 text-[Inter] text-xl ">
          {texts.packages}
        </span>
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none py-4"
        >
          <div className="flex flex-col justify-between md:flex-row md:items-center gap-4">
            <Button
              className="w-60 flex items-center font-[Inter] justify-center"
              color="blue"
              size="sm"
              onClick={() => {
                navigate("/packages/add-package");
              }}
            >
              <PlusCircleIcon className="h-5 w-5 mr-2" />
              {texts.addPackage}
            </Button>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {packages.map((item, index) => {
                    const isLast = index === packages.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.id}>
                        <td className={classes}>
                          <PackageName
                            item={item}
                            currentLanguage={currentLanguage}
                          />
                        </td>
                        <td className={classes}>
                          <span>{item.packageDescription}</span>
                        </td>
                        <td className={classes}>
                          <span>{item.packageRegion}</span>
                        </td>

                        <td className={classes}>
                          <span>
                            {item.package3Montly} {texts.pricePerMonth}
                          </span>
                        </td>
                        <td className={classes}>
                          <span>
                            {item.package6Montly} {texts.pricePerMonth}
                          </span>
                        </td>
                        <td className={classes}>
                          <span>
                            {item.package12Montly} {texts.pricePerMonth}
                          </span>
                        </td>

                        <td className={classes}>
                          <Tooltip
                            content={texts.packagesTableTitle.packageDetails}
                          >
                            <IconButton
                              variant="text"
                              onClick={() => handleShowImages(item)}
                            >
                              <EyeIcon className="h-6 w-6 text-blue-500" />
                            </IconButton>
                          </Tooltip>
                          {
                            <Tooltip
                              content={texts.packagesTableTitle.editPackage}
                            >
                              <IconButton
                                variant="text"
                                onClick={() => {
                                  navigate(
                                    `/packages/edit-package/${item.packageId}`
                                  );
                                }}
                              >
                                <PencilIcon className="h-6 w-6" />
                              </IconButton>
                            </Tooltip>
                          }
                          <Tooltip content={texts.deletePackage}>
                            <IconButton
                              variant="text"
                              onClick={() => handleOpenDeletePackageModal(item)}
                            >
                              <TrashIcon className="h-6 w-6 text-red-500" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
        <Dialog
          handler={() => setDeletePackageModal(false)}
          open={deletePackageModal}
          onClose={() => setDeletePackageModal(false)}
        >
          <DialogBody>
            <span className="font-[Inter] text-lg">
              {texts.packageDeleteInfo}
            </span>
          </DialogBody>
          <DialogFooter>
            <Button
              color="blue"
              className="font-[Inter] mr-2"
              onClick={() => setDeletePackageModal(false)}
            >
              {texts.no}
            </Button>
            <Button
              loading={deleteLoading}
              color="red"
              className="font-[Inter]"
              onClick={handleDeletePackage}
            >
              {texts.yes}
            </Button>
          </DialogFooter>
        </Dialog>

        <Dialog
          open={showImagesModal}
          handler={() => setShowImagesModal(false)}
          onClose={() => setShowImagesModal(false)}
          size="lg"
        >
          <DialogHeader className="font-[Inter]">
            {texts.packagesTableTitle.packageDetails}
          </DialogHeader>
          <DialogBody>
            <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
              <TabsHeader>
                <Tab className="font-[Inter]" value="1">
                  {texts.packagesTableTitle.packageInformation}
                </Tab>
                <Tab className="font-[Inter]" value="2">
                  {texts.Images}
                </Tab>
              </TabsHeader>
              <TabsBody>
                <TabPanel value="1">
                  <div className="overflow-y-auto max-h-96 p-4 bg-gray-100 rounded-md shadow-lg font-[Inter]">
                    <h2 className="text-xl font-bold mb-4">
                      <PackageName
                        item={selectedPackage}
                        currentLanguage={currentLanguage}
                      />
                    </h2>
                    <div className="space-y-2">
                      <p className="text-gray-700">
                        <strong>
                          {texts.packagesTableTitle.packageDescription}:{" "}
                        </strong>
                        {selectedPackage?.packageDescription}
                      </p>
                      <p className="text-gray-700">
                        <strong>
                          {texts.packagesTableTitle.packageRegion}:{" "}
                        </strong>
                        {selectedPackage?.packageRegion}
                      </p>
                      <p className="text-gray-700">
                        <strong>
                          {texts.packagesTableTitle.package3Monthly}:{" "}
                        </strong>
                        {selectedPackage?.package3Montly}
                      </p>
                      <p className="text-gray-700">
                        <strong>
                          {texts.packagesTableTitle.package6Monthly}:{" "}
                        </strong>
                        {selectedPackage?.package6Montly}
                      </p>
                      <p className="text-gray-700">
                        <strong>
                          {texts.packagesTableTitle.packageYearly}:{" "}
                        </strong>
                        {selectedPackage?.package12Montly}
                      </p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className="flex flex-wrap justify-center gap-4 w-full">
                    {selectedPackage?.packageImages?.map((image, index) => (
                      <img
                        key={index}
                        src={API_URL + ":" + API_PORT + "/" + image.imageName}
                        alt={image.imageName}
                        className="w-1/6"
                      />
                    ))}
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          </DialogBody>
          <DialogFooter>
            <Button
              color="blue"
              className="font-[Inter]"
              onClick={() => setShowImagesModal(false)}
            >
              {texts.close}
            </Button>
          </DialogFooter>
        </Dialog>
      </Card>
    </>
  );
};

export default Index;
