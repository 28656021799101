import React from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import logo from "../assets/images/logo.svg";
import { languages } from "../constants/languages/languages";
import { Select, Option } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  ArchiveBoxIcon,
  ListBulletIcon,
  RectangleStackIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  PhotoIcon,
  ArrowUpOnSquareStackIcon,
  UserGroupIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import CanViewItem from "./CanViewItem";
import { useLanguage } from "./languageProvider";

const Leftmenu = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const leftMenuItems = languages[currentLanguage];
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const countries = [
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "fr",
      name: "French",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/fr.svg",
      },
    },
    {
      id: "it",
      name: "Italian",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/it.svg",
      },
    },
    {
      id: "nl",
      name: "Dutch",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/nl.svg",
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="h-8 w-18">
        <Button
          onClick={openDrawer}
          className="bg-blue-500 font-[Inter] text-white rounded-xl items-center shadow-xl"
        >
          {leftMenuItems.menu}
        </Button>
      </div>
      <Drawer open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">
          <img src={logo} alt="logo" className="w-10 h-10" />
          <Typography variant="h5" color="blue-gray" className="font-[Inter]">
            FLYING.COM
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <List>
          <ListItem
            className="font-[Inter] text-sm"
            onClick={() => navigate("/")}
          >
            <ListItemPrefix>
              <HomeIcon className="w-6 h-6" />
            </ListItemPrefix>
            {leftMenuItems.homePage}
          </ListItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/products")}
            >
              <ListItemPrefix>
                <ArchiveBoxIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.products}
            </ListItem>
          </CanViewItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/category")}
            >
              <ListItemPrefix>
                <ListBulletIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.categoriesAndImages}
            </ListItem>
          </CanViewItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("category/sendedCategoryImages")}
            >
              <ListItemPrefix>
                <ArrowUpOnSquareStackIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.sendedCategoryImages}
            </ListItem>
          </CanViewItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/dealer")}
            >
              <ListItemPrefix>
                <BuildingOffice2Icon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.dealer}
            </ListItem>
          </CanViewItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/payments")}
            >
              <ListItemPrefix>
                <BanknotesIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.payments}
            </ListItem>
          </CanViewItem>
          <ListItem
            className="font-[Inter] text-sm"
            onClick={() => navigate("/users")}
          >
            <ListItemPrefix>
              <UserGroupIcon className="w-6 h-6" />
            </ListItemPrefix>
            {leftMenuItems.users}
          </ListItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/packages")}
            >
              <ListItemPrefix>
                <RectangleStackIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.packages}
            </ListItem>
          </CanViewItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/background-images")}
            >
              <ListItemPrefix>
                <PhotoIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.userBackgrondImages}
            </ListItem>
          </CanViewItem>
          <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/print")}
            >
              <ListItemPrefix>
                <PrinterIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.printerPage}
            </ListItem>
          </CanViewItem>
          {/*  
            <CanViewItem canview="superadmin">
            <ListItem
              className="font-[Inter] text-sm"
              onClick={() => navigate("/support-request")}
            >
              <ListItemPrefix>
                <ChatBubbleLeftRightIcon className="w-6 h-6" />
              </ListItemPrefix>
              {leftMenuItems.supportRequests}
            </ListItem>
          </CanViewItem>
          */}
          <div className="w-72 pt-2 px-2 pl-2">
            <Select
              size="lg"
              variant="outlined"
              className="flex w-full font-[Inter]"
              value={currentLanguage}
              label={leftMenuItems.selectLanguages}
              onChange={(e) => changeLanguage(e)}
              selected={(element) =>
                element &&
                React.cloneElement(element, {
                  disabled: true,
                  className:
                    "flex items-center  opacity-100 px-0 gap-2 pointer-events-none",
                })
              }
            >
              {countries.map(({ name, flags, id }) => (
                <Option key={id} value={id} className="flex items-center gap-2">
                  <img
                    src={flags.svg}
                    alt={name}
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  {name}
                </Option>
              ))}
            </Select>
          </div>
        </List>
      </Drawer>
    </React.Fragment>
  );
};
export default Leftmenu;
