import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Button,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import { useLanguage } from "../../components/languageProvider";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";
import moment from "moment";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [payments, setPayments] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const getAllPayments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/payment/getAllPayment?page=${page}&size=${size}&sort=createdAt,desc`
      );
      const data = await response.json();
      setPayments(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }; // Tüm ödemeleri getirmek için kullanılan fonksiyon

  const handleUpdatePaymentStatus = async (payment, status) => {
    try {
      const updatePayment = {
        paymentId: payment.paymentId,
        status: status === "true" ? true : false,
        user: {
          userId: payment.user.userId,
        },
        paymentAmount: payment.paymentAmount,
        paymentDate: payment.paymentDate,
        paymentDescription: payment.paymentDescription,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/payment/savePayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePayment),
        }
      );
      const data = await response.json();
      if (data) {
        toast.success(texts.paymentTableTitle.paymentStatusUpdated);
        getAllPayments(); // Tüm ödemeleri getirme işlemi
      }
    } catch (error) {
      console.log(error);
    }
  }; // Ödemeyi güncelemek için kullanılan fonksiyon

  useEffect(() => {
    getAllPayments();
  }, [page]); // Tüm ödemeleri getirme işlemi sayfa değiştiğinde çalışacak
  const TABLE_HEAD = [
    texts.paymentTableTitle.userName,
    texts.paymentTableTitle.paymentAmount,
    texts.paymentTableTitle.paymentStatus,
    texts.paymentTableTitle.paymentDate,
    texts.paymentTableTitle.paymentDescription,
    "",
  ]; // Ödeme tablosunun başlıkları
  const handlePageChange = (newPage) => {
    setPage(newPage);
  }; // Sayfa değiştiğinde çalışacak fonksiyon

  const handleClickDescription = (item) => {
    toast.info(item.paymentDescription, {
      position: "top-center", // Center it on the screen
      icon: "📝", // Add a pen emoji to the toast
      style: {
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
        border: "1px solid #333",
        fontSize: "1.5rem",
      },
      className: "text-sm font-[Inter] bg-blue-gray-800 text-white",
    });
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <span className="px-4 py-4 text-[Inter] text-xl ">
          {texts.payments}
        </span>
        <CardHeader floated={false} shadow={false} className="rounded-none ">
          <div className="flex flex-col justify-between md:flex-row md:items-center p-2">
            <Input
              label={texts.search}
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            />
          </div>
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {payments.map((item, index) => {
                    const isLast = index === payments.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.paymentId}>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <span>
                              {item?.user?.userName +
                                " " +
                                item?.user?.userSurname}
                              <br />
                              {item?.user?.userFirmName}
                            </span>
                          </div>
                        </td>
                        <td className={classes}>
                          <span>{item.paymentAmount}</span>
                        </td>
                        <td className={classes}>
                          {item?.status ? (
                            <span className="text-green-500">
                              {
                                texts.printRequestTableTitle
                                  .printPaymentStatusPaid
                              }
                            </span>
                          ) : (
                            <span className="text-red-500">
                              {
                                texts.printRequestTableTitle
                                  .printPaymentStatusUnpaid
                              }
                            </span>
                          )}
                        </td>
                        <td className={classes}>
                          <span>
                            {item.paymentDate &&
                              moment(item.paymentDate).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                          </span>
                        </td>
                        <td className={classes}>
                          <Button
                            className="items-center text-xs justify-center font-[Inter]"
                            color="black"
                            size="sm"
                            variant="outlined"
                            onClick={() => {
                              handleClickDescription(item);
                            }}
                          >
                            <span> {texts.paymentTableTitle.description}</span>
                          </Button>
                        </td>
                        <td className={classes}>
                          <select
                            className="border border-blue-gray-200 rounded-md p-1"
                            name="status"
                            id="status"
                            value={item.status ? "true" : "false"}
                            onChange={(e) => {
                              handleUpdatePaymentStatus(item, e.target.value);
                            }}
                          >
                            <option value="true">
                              {
                                texts.printRequestTableTitle
                                  .printPaymentStatusPaid
                              }
                            </option>
                            <option value="false">
                              {
                                texts.printRequestTableTitle
                                  .printPaymentStatusUnpaid
                              }
                            </option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
