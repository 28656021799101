import {
  Card,
  Button,
  CardBody,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import React, { useRef, useState, useEffect } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DeafultUserImage from "../../../assets/images/defaultUserImage.png";

const getPackageName = (pkg, currentLanguage) => {
  const packageName = {
    tr: pkg.packageNameTr,
    de: pkg.packageNameDe,
    fr: pkg.packageNameFr,
    it: pkg.packageNameIt,
    nl: pkg.packageNameNl,
    en: pkg.packageNameEn,
  };

  return packageName[currentLanguage] || packageName.en;
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [loading, setLoading] = useState(false);
  const [userDealer, setUserDealer] = useState();
  const [imageData, setImageData] = useState(null);
  const [userPackage, setUserPackage] = useState({ packageId: "" });
  const [userBankAccount, setUserBankAccount] = useState({
    accountNumber: "",
    iban: "",
    bankName: "",
  });
  const [isSelectedPackageMonth, setIsSelectedPackageMonth] = useState();
  const [user, setUser] = useState({
    userName: "",
    userSurname: "",
    userUsername: "",
    userPassword: "",
    userRole: "user",
    userTrial: false,
    userEmail: "",
    userPhone: "",
    userFirmName: "",
    userTaxNumber: "",
    userSelectedBg: "",
    dealer: userDealer,
  });
  const [changeImage, setChangeImage] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [dealers, setDealers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [userSaving, setUserSaving] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const getAllDealer = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/dealer/getAllDealer?page=0&size=9999999`
      );
      const data = await response.json();
      setDealers(data?.content || []);
      setUserDealer(data?.content[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const getAllPackage = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBg?page=0&size=9999999`
      );
      const data = await response.json();
      setPackages(data?.content || []);
      setUserPackage(data?.content[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllDealer();
    getAllPackage();
  }, []);

  const handleImageSelection = (imageId) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageId)) {
        return prevSelectedImages.filter((id) => id !== imageId);
      } else {
        return [...prevSelectedImages, imageId];
      }
    });
  };

  const handleSelectDealerChange = (selectedOption) => {
    const selectedDealer = dealers.find(
      (dealer) => dealer.dealerId === selectedOption.value
    );
    setUserDealer(selectedDealer);
  };

  const dealerOptions = dealers.map((dealer) => ({
    value: dealer.dealerId,
    label: `${dealer.dealerPostalCode} - ${dealer.dealerName} - ${dealer.dealerAddress}`,
  }));

  const handleSelectPackageChange = (selectedOption) => {
    const selectedPackage = packages.find(
      (pkg) => pkg.packageId === selectedOption.value
    );
    setUserPackage(selectedPackage);
  };

  const packageOptions = packages.map((pkg) => ({
    value: pkg.packageId,
    label: getPackageName(pkg, currentLanguage),
  }));

  const selectedPackageOption = userPackage
    ? {
        value: userPackage.packageId,
        label: getPackageName(userPackage, currentLanguage),
      }
    : null;

  const handleSelectPriceType = (value) => {
    setIsSelectedPackageMonth(value);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const checkAspectRatio = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const isSquare = image.width === image.height;
          resolve(isSquare);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      myToast({ status: "error", message: texts.fileExtensionError });
      return;
    }

    const isSquare = await checkAspectRatio(file);
    if (!isSquare) {
      myToast({ status: "error", message: texts.fileAspectRatioError });
      return;
    }

    setNewImage(file);
    setChangeImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const uploadImage = async (userImage) => {
    try {
      const formData = new FormData();
      formData.append("file", userImage);
      const categorytype = "userimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${categorytype}`,
        {
          method: "POST",
          body: formData,
        }
      );
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const saveUser = async (sendedImage) => {
    try {
      const userDto = {
        userDTO: {
          userName: user.userName,
          userSurname: user.userSurname,
          userUsername: user.userUsername,
          userPassword: user.userPassword,
          userRole: user.userRole,
          userTrial: user.userTrial,
          userEmail: user.userEmail,
          userPhone: user.userPhone,
          userFirmName: user.userFirmName,
          userTaxNumber: user.userTaxNumber,
          userSelectedBg: selectedImages.join(","),
          dealer: {
            dealerId: userDealer?.dealerId,
          },
          image: {
            imageId: sendedImage?.imageId,
          },
        },
        bankAccountDTO: userBankAccount,
        apackage: {
          packageId: userPackage.packageId,
        },
        isSelectedPackageMonth: isSelectedPackageMonth,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/user/CollectiveSaveUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDto),
        }
      );

      return await response.json();
    } catch (error) {
      myToast({ status: "error", message: texts.saveStatus.userSaveError });
    }
  };

  const saveUserImageAndUserRelation = async (imageId, userId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/saveImageUserRelation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            image: {
              imageId: imageId,
            },
            user: { userId: userId },
            imageType: "userimages",
            status: true,
          }),
        }
      );
      return await response.json();
    } catch (error) {
      myToast({ status: "error", message: texts.saveStatus.relationSaveError });
    }
  };

  const saveUserAndImage = async () => {
    setUserSaving(true);
    try {
      const defaultUserImage = await fetch(DeafultUserImage);
      const defaultUserImageBlob = await defaultUserImage.blob();
      const defaultUserImageFile = new File(
        [defaultUserImageBlob],
        "defaultUserImage.png"
      );
      const userImage = newImage ? newImage : defaultUserImageFile;
      let imageResponse = await uploadImage(userImage);
      if (!imageResponse?.imageId) {
        myToast({
          status: "error",
          message: texts.saveStatus.imageSaveError,
        });
        return;
      }

      const userResponse = await saveUser(imageResponse);

      if (userResponse?.userDTO?.userId) {
        if (imageResponse?.imageId) {
          const relationResponse = await saveUserImageAndUserRelation(
            imageResponse.imageId,
            userResponse?.userDTO?.userId
          );
          if (!relationResponse) {
            myToast({
              status: "error",
              message: texts.saveStatus.relationSaveError,
            });
            return;
          }
        }
        myToast({
          status: "success",
          message: texts.saveStatus.userSaveSuccess,
        });
        navigate("/users");
      } else {
        myToast({
          status: "error",
          message: texts.saveStatus.userSaveError,
        });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.userSaveError });
    }
    setUserSaving(false);
  };

  const handleSave = async () => {
    await saveUserAndImage();
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <Spinner className="h-40 w-40" />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <h2>{texts.userTableTitle.addUser}</h2>
          <div className="rounded-none gap-8">
            <div className="relative h-72 w-full flex justify-center">
              <img
                className="h-[300px] w-[300px] rounded-xl object-cover object-center shadow-xl"
                src={imageData ? imageData : "https://placehold.co/1000x1000"}
                alt="categoryImage"
              />
              <input
                type="file"
                id="file-upload"
                accept="image/jpeg, image/png, image/jpg, image/heic"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-upload"
                className="absolute font-[Inter] flex items-center justify-center cursor-pointer"
                onClick={triggerFileInput}
              >
                <Button
                  className="w-[300px] rounded-none rounded-t-xl opacity-75 font-[Inter]"
                  color="blue"
                  size="md"
                >
                  {texts.addImage}
                </Button>
              </label>
            </div>
          </div>
          <CardBody className="px-0 flex flex-col gap-2">
            <Checkbox
              color="blue"
              label={texts.userTableTitle.freeTrial}
              checked={user.userTrial || false}
              onChange={(e) =>
                setUser({ ...user, userTrial: e.target.checked })
              }
            />
            <Input
              label={texts.userTableTitle.name}
              onChange={(e) => setUser({ ...user, userName: e.target.value })}
              value={user.userName || ""}
            />
            <Input
              label={texts.userTableTitle.surname}
              onChange={(e) =>
                setUser({ ...user, userSurname: e.target.value })
              }
              value={user.userSurname || ""}
            />
            <Input
              label={texts.userTableTitle.userName}
              onChange={(e) =>
                setUser({ ...user, userUsername: e.target.value })
              }
              value={user.userUsername || ""}
            />
            <Input
              label={texts.userTableTitle.userTaxNumber}
              onChange={(e) =>
                setUser({ ...user, userTaxNumber: e.target.value })
              }
              value={user.userTaxNumber || ""}
            />

            <Input
              label={texts.userTableTitle.phone}
              onChange={(e) => setUser({ ...user, userPhone: e.target.value })}
              value={user.userPhone || ""}
            />
            <Input
              label={texts.userTableTitle.email}
              onChange={(e) => setUser({ ...user, userEmail: e.target.value })}
              value={user.userEmail || ""}
            />
            <Input
              label={texts.userTableTitle.userPassword}
              onChange={(e) =>
                setUser({ ...user, userPassword: e.target.value })
              }
              value={user.userPassword || ""}
            />

            <Input
              label={texts.userTableTitle.companyName}
              onChange={(e) =>
                setUser({ ...user, userFirmName: e.target.value })
              }
              value={user.userFirmName || ""}
            />
            <Input
              label={texts.userTableTitle.userIBAN}
              onChange={(e) =>
                setUserBankAccount({ ...userBankAccount, iban: e.target.value })
              }
              value={userBankAccount.iban || ""}
            />
            <Input
              label={texts.userTableTitle.userBankName}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankName: e.target.value,
                })
              }
              value={userBankAccount.bankName || ""}
            />
            <Input
              label={texts.userTableTitle.userAccountNumber}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  accountNumber: e.target.value,
                })
              }
              value={userBankAccount.accountNumber || ""}
            />

            <Select
              className="w-full h-10  rounded-lg"
              value={
                userDealer
                  ? {
                      value: userDealer.dealerId,
                      label: `${userDealer.dealerPostalCode} - ${userDealer.dealerName} - ${userDealer.dealerAddress}`,
                    }
                  : null
              }
              onChange={handleSelectDealerChange}
              options={dealerOptions}
              placeholder="Select a dealer..."
            />
            <Select
              className="w-full h-10 rounded-lg"
              value={selectedPackageOption}
              onChange={handleSelectPackageChange}
              options={packageOptions}
              placeholder="Select a package..."
            />

            {userPackage && (
              <div className="price-selection flex flex-wrap gap-4 mt-4">
                <label className="price-box flex flex-row gap-2">
                  <input
                    type="radio"
                    name="package"
                    value="3"
                    checked={isSelectedPackageMonth === "3"}
                    onChange={() => handleSelectPriceType("3")}
                  />
                  <span>
                    {texts.packagesTableTitle.package3Monthly} -{" "}
                    {userPackage.package3Montly} €
                  </span>
                </label>
                <label className="price-box flex flex-row gap-2">
                  <input
                    type="radio"
                    name="package"
                    value="6"
                    checked={isSelectedPackageMonth === "6"}
                    onChange={() => handleSelectPriceType("6")}
                  />
                  <span>
                    {texts.packagesTableTitle.package6Monthly} -{" "}
                    {userPackage.package6Montly} €
                  </span>
                </label>
                <label className="price-box flex flex-row gap-2">
                  <input
                    type="radio"
                    name="package"
                    value="12"
                    checked={isSelectedPackageMonth === "12"}
                    onChange={() => handleSelectPriceType("12")}
                  />
                  <span>
                    {texts.packagesTableTitle.packageYearly} -{" "}
                    {userPackage.package12Montly} €
                  </span>
                </label>
              </div>
            )}

            {userPackage && userPackage.packageImages && (
              <div className="image-selection flex flex-wrap gap-4 mt-4">
                {userPackage.packageImages.map((item) => (
                  <div
                    key={item.imageId}
                    onClick={() => handleImageSelection(item.imageId)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      border: "2px solid",
                      borderColor: selectedImages.includes(item.imageId)
                        ? "blue"
                        : "gray",
                      borderRadius: "8px",
                      overflow: "hidden",
                      width: "100px",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={`${API_URL}:${API_PORT}/${item.imageName}`}
                      alt={item.imageName}
                      style={{
                        display: "block",
                        width: "100px",
                        transition: "opacity 0.3s ease",
                        opacity: selectedImages.includes(item.imageId)
                          ? 0.6
                          : 1,
                        objectFit: "cover",
                      }}
                    />
                    {selectedImages.includes(item.imageId) && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "white",
                          fontSize: "24px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        ✓
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            <Button
              color="blue"
              className="w-full font-[Inter]"
              onClick={handleSave}
              loading={userSaving}
            >
              {user.userId
                ? texts.userTableTitle.updateUser
                : texts.userTableTitle.addUser}
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Index;
