import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  CardFooter,
  Dialog,
  IconButton,
  Tooltip,
  Spinner,
  Button,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../components/languageProvider";
import Pagination from ".././../../components/Pagination";
import EmptyImage from "../../../assets/images/noImage.svg";
import { useRef } from "react";
import myToast from "../../../components/myToast";
import { useNavigate } from "react-router-dom";

const CategoryName = ({ item, currentLanguage }) => {
  const categoryName = {
    tr: item.categoryNameTr,
    de: item.categoryNameDe,
    fr: item.categoryNameFr,
    it: item.categoryNameIt,
    nl: item.categoryNameNl,
    en: item.categoryNameEn,
  };

  return (
    <span color="blue-gray" className="text-xs font-bold font-[Inter]">
      {categoryName[currentLanguage] || categoryName.en}
    </span>
  );
};
const SubCategoryName = ({ item, currentLanguage }) => {
  const subCategoryName = {
    tr: item.subCategoryNameTr,
    de: item.subCategoryNameDe,
    fr: item.subCategoryNameFr,
    it: item.subCategoryNameIt,
    nl: item.subCategoryNameNl,
    en: item.subCategoryNameEn,
  };
  return (
    <span color="blue-gray" className="font-bold font-[Inter]">
      {subCategoryName[currentLanguage] || subCategoryName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();

  const texts = languages[currentLanguage];
  const [categories, setCategories] = useState([]);
  const size = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [imageData, setImageData] = useState();
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Butona tıklandığında dosya seçme inputunu tetikletiyoruz
  };

  const fileInputRef = useRef(null); // useRef ile bir referans oluşturuluyor

  const TABLE_HEAD = [
    texts.categoryImage,
    texts.userTableTitle.userName,
    texts.userTableTitle.phone,
    texts.category,
    "",
  ];

  const getAllCategory = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/imageRequest/getAllImageRequest?page=${currentPage}&size=${size}`
      );
      const data = await response.json();
      setCategories(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllCategory();
  }, [currentPage]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDownloadSendedImage = (imageName) => {
    fetch(`${API_URL}:${API_PORT}/${imageName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imageName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleOpenUpdateImage = (item) => {
    setOpenUpdateDialog(true);
    setSelectedProduct(item);
  };
  const checkAspectRatio = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const width = this.width;
          const height = this.height;
          if (width === height) {
            resolve(true); // Resmin oranı 1:1
          } else {
            resolve(false); // Resmin oranı 1:1 değil
          }
        };
      };
      reader.readAsDataURL(file);
    });
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0]; // Yüklenen dosyayı alın

    // Dosya uzantısını kontrol etme
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      // Uzantı JPEG veya PNG değilse, işlemi durdur
      myToast({ status: "error", message: texts.fileExtensionError });
      return;
    }

    // Resmin oranını kontrol etme
    const isSquare = await checkAspectRatio(file);
    if (!isSquare) {
      myToast({ status: "error", message: texts.fileAspectRatioError });
      return;
    }

    // Resmi base64'e çevirme işlemi
    setImage(file); // Resmi state içerisine atama
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result; // Resmi base64 olarak alın
      setImageData(imageData); // Resmi state içerisine atama
    };
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      const categorytype = "subcategoryimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${categorytype}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleRelationCategoryAndImage = async (imageId) => {
    try {
      const ENDPOINT = "ImageSubCategoryRelation/saveImageSubCategoryRelation";
      var relation = {
        image: {
          imageId: imageId,
        },
        subCategory: {
          subCategoryId: selectedProduct?.subCategory?.subCategoryId,
        },
        status: true,
      };
      const response = await fetch(`${API_URL}:${API_PORT}/${ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(relation),
      });

      if (!response.ok) {
        throw new Error(texts.categoryImageRelationError); // Hata fırlat
      } else {
        return response; // Başarı durumunda response'u döndür
      }
    } catch (error) {
      throw error; // Hata fırlat
    }
  };
  const saveImage = async () => {
    try {
      const imageResponse = await uploadImage();
      if (imageResponse && imageResponse?.imageId) {
        const relationResponse = await handleRelationCategoryAndImage(
          imageResponse?.imageId
        );
        if (relationResponse.ok) {
          myToast({ status: "success", message: texts.categorySaveSuccess });
          navigate(
            "/category/reviewSubCategory/" +
              selectedProduct?.subCategory?.subCategoryId
          );
        } else {
          myToast({
            status: "error",
            message: texts.categoryImageRelationError,
          });
        }
      } else {
        myToast({ status: "error", message: texts.categorySaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.categorySaveError });
    }
  };
  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <Spinner className="h-40 w-40" />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl mx-4 ">
          <span className="px-4 py-2 text-[Inter] text-xl ">
            {texts.categories}
          </span>
          <CardBody className="px-0">
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={`head_${index}`} // Başlık anahtarı oluşturuyoruz
                        className="justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="text-sm  font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {categories.map((item, index) => {
                    const isLast = index === categories.length - 1;
                    const classes = isLast
                      ? "p-4 font-[Inter]"
                      : "p-4 border-b font-[Inter] border-blue-gray-50";
                    return (
                      <tr key={item.categoryId}>
                        <td className={classes}>
                          <div className="relative flex items-center">
                            <img
                              src={
                                item?.image?.imageName
                                  ? `${API_URL}:${API_PORT}/${item?.image?.imageName}`
                                  : EmptyImage
                              }
                              alt={item?.categoryNameEn}
                              className="border font-[Inter]  w-[80px] object-cover rounded-md border-blue-gray-50 bg-blue-gray-50/50"
                            />
                          </div>
                        </td>
                        <td className={classes}>
                          <span className="font-bold font-[Inter]">
                            {item?.user?.userName +
                              " " +
                              item?.user?.userSurname}
                            <br />
                            {item?.user?.userFirmName}
                          </span>
                        </td>
                        <td className={classes}>
                          <span className="font-bold font-[Inter]">
                            {item?.user?.userPhone}
                          </span>
                        </td>

                        <td className={classes}>
                          <SubCategoryName
                            item={item?.subCategory}
                            currentLanguage={currentLanguage}
                          />
                        </td>

                        <td className={classes}>
                          <Tooltip content={texts.downloadSendedImage}>
                            <IconButton
                              variant="text"
                              color="blue"
                              onClick={() => {
                                handleDownloadSendedImage(
                                  item?.image?.imageName
                                );
                              }}
                            >
                              <ArrowDownOnSquareIcon className="h-7 w-7" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content={texts.uploadEditedImage}>
                            <IconButton
                              variant="text"
                              color="blue"
                              onClick={() => {
                                handleOpenUpdateImage(item);
                              }}
                            >
                              <ArrowUpOnSquareIcon className="h-7 w-7" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </CardFooter>
          <Dialog
            open={openUpdateDialog}
            onClose={() => setOpenUpdateDialog(false)}
          >
            <DialogBody>
              <div className="relative h-72 w-full flex justify-center">
                <img
                  className="h-[300px] w-[300px] rounded-xl object-cover object-center shadow-xl"
                  src={imageData || "https://placehold.co/1000x1000"}
                  alt="categoryImage"
                />
                <input
                  type="file"
                  id="file-upload"
                  accept="image/jpeg, image/png, image/jpg, image/heic" // Sadece resim dosyalarını seçebilmesi için
                  ref={fileInputRef} // useRef ile oluşturulan referans burada atanıyor
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="file-upload"
                  className="absolute  font-[Inter] flex items-center justify-center cursor-pointer"
                  onClick={triggerFileInput} // Butona tıklandığında triggerFileInput fonksiyonu çağrılıyor
                >
                  <Button
                    className="w-[300px] rounded-none rounded-t-xl opacity-75 font-[Inter]"
                    color="blue"
                    size="md"
                  >
                    {texts.addImage}
                  </Button>
                </label>
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                color="red"
                className="font-[Inter] mr-2"
                onClick={() => setOpenUpdateDialog(false)}
              >
                {texts.no}
              </Button>
              <Button
                color="green"
                className="font-[Inter]"
                loading={updateLoading}
                onClick={saveImage}
              >
                {texts.yes}
              </Button>
            </DialogFooter>
          </Dialog>
        </Card>
      )}
    </>
  );
};

export default Index;
