import {
  Card,
  Button,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";
const Index = () => {
  const { id } = useParams();

  useEffect(() => {
    getAllCategories();
    if (id) {
      getSubCategoriesByCategoryId(id);
    }
  }, []);

  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].addCategoryItems;
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState({
    subCategoryId: "",
    subCategoryNameTr: "",
    subCategoryNameEn: "",
    subCategoryNameDe: "",
    subCategoryNameFr: "",
    subCategoryNameIt: "",
    subCategoryNameNl: "",
    subCategoryDescription: "",
    subCategorySerial: "",
    language: currentLanguage,
    subCategoryStatus: true,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/category/getAllCategory?page=${0}&size=${1000000}`
      );
      const data = await response.json();
      setSelectedMainCategory(data?.content[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getSubCategoriesByCategoryId = async (categoryId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/subCategory/findBySubCategoryId?subCategoryId=${categoryId}`
      );
      const data = await response.json();
      setSubCategory(data);
    } catch (error) {
      console.log(error);
    }
  };

  const saveSubCategory = async () => {
    try {
      const payload = {
        ...subCategory,
        subCategorySerial: subCategory?.subCategoryNameEn,
        category: {
          categoryId: selectedMainCategory?.categoryId,
        },
      };
      const saveResponse = await fetch(
        `${API_URL}:${API_PORT}/subCategory/saveSubCategory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      return saveResponse;
    } catch (error) {
      throw error; // Hata fırlatılmalı
    }
  };

  const saveCategory = async () => {
    try {
      const saveResponse = await saveSubCategory();
      if (saveResponse.status === 200) {
        myToast({ status: "success", message: texts.categorySaveSuccess });
        navigate("/category");
      } else {
        myToast({ status: "error", message: texts.categorySaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.categorySaveError });
    }
  };

  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <Card className="w-full h-full shadow-2xl p-4 ">
        {texts.addCategory}

        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col gap-2">
              <div>
                <Input
                  label={texts.categoryNameDe}
                  value={subCategory?.subCategoryNameDe}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryNameDe: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.categoryNameEn}
                  value={subCategory?.subCategoryNameEn}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryNameEn: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.categoryNameTr}
                  value={subCategory?.subCategoryNameTr}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryNameTr: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.categoryNameFr}
                  value={subCategory?.subCategoryNameFr}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryNameFr: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.categoryNameIt}
                  value={subCategory?.subCategoryNameIt}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryNameIt: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.categoryNameNl}
                  value={subCategory?.subCategoryNameNl}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryNameNl: e.target.value,
                    })
                  }
                />
              </div>

              <div>
                <Input
                  label={texts.categoryDescription}
                  className="font-[Inter]"
                  value={subCategory?.subCategoryDescription}
                  onChange={(e) =>
                    setSubCategory({
                      ...subCategory,
                      subCategoryDescription: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex justify-end">
          <Button
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1 font-[Inter]"
          >
            <span>{texts.categoryCancel}</span>
          </Button>
          <Button
            onClick={() => saveCategory()}
            className="font-[Inter]"
            color="green"
          >
            <span>{texts.categorySave}</span>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
