import {
  Card,
  Button,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";
const Index = () => {
  const { id } = useParams();
  const { currentLanguage } = useLanguage();
  const navigate = useNavigate();
  const texts = languages[currentLanguage];
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState({
    printNameTr: "",
    printNameEn: "",
    printNameDe: "",
    printNameFr: "",
    printNameIt: "",
    printNameNl: "",
    printDescription: "",
    printPrice: "",
  });

  const savePrice = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}:${API_PORT}/print/savePrint`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(print),
      });
      if (response?.status === 200) {
        myToast({
          status: "success",
          message: texts.successMessages.saveSuccess,
        });
        navigate("/print/review-prices");
      } else {
        myToast({
          status: "error",
          message: texts.errorMessages.saveError,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getPrintPrice = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/findByLikePrintId?printId=${id}`
      );
      const data = await response.json();
      setPrint(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getPrintPrice();
    }
  }, [id]);

  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <Card className="w-full h-full shadow-2xl p-4 ">
        {texts.printPriceTableTitle.addPrice}

        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col gap-2">
              <div>
                <Input
                  label={texts.addPrintTable.printNameDe}
                  value={print?.printNameDe}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printNameDe: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printNameEn}
                  value={print?.printNameEn}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printNameEn: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printNameTr}
                  value={print?.printNameTr}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printNameTr: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printNameFr}
                  value={print?.printNameFr}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printNameFr: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printNameIt}
                  value={print?.printNameIt}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printNameIt: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printNameNl}
                  value={print?.printNameNl}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printNameNl: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printDescription}
                  value={print?.printDescription}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printDescription: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <Input
                  label={texts.addPrintTable.printPrice}
                  value={print?.printPrice}
                  className="font-[Inter]"
                  onChange={(e) =>
                    setPrint({
                      ...print,
                      printPrice: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex justify-end">
          <Button
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1 font-[Inter]"
          >
            <span>{texts.addPrintTable.printCancel}</span>
          </Button>
          <Button
            onClick={() => savePrice()}
            className="font-[Inter]"
            color="green"
          >
            <span>{texts.addPrintTable.printSave}</span>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
