import React from "react";
import { useUserRole } from "./UserRoleProvider"; // Yolunuza göre ayarlayın

const CanViewItem = ({ children, canview }) => {
  const { userRole } = useUserRole();

  if (
    userRole === "superadmin" ||
    canview === "everyone" ||
    userRole === canview
  ) {
    return <div>{children}</div>;
  } else {
    return null;
  }
};

export default CanViewItem;
