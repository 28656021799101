import {
  Card,
  Button,
  CardBody,
  CardFooter,
  Checkbox,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import React, { useRef, useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].addBackgroundImages;
  const [openModal, setOpenModal] = React.useState(false);
  const [image, setImage] = useState();
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [multipleBackgroundImageState, setMultipleBackgroundImageState] =
    useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null); // useRef ile bir referans oluşturuluyor
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Butona tıklandığında dosya seçme inputunu tetikletiyoruz
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const options = users.map((user) => ({
    value: user.userId,
    label: user.userName + " " + user.userSurname + " - " + user.userFirmName,
  }));

  const checkAspectRatio = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const width = this.width;
          const height = this.height;
          const aspectRatio = height / width;

          if (aspectRatio >= 1.41 && aspectRatio < 1.42) {
            resolve(true); // Resmin oranı 1:1
          } else {
            resolve(false); // Resmin oranı 1:1 değil
          }
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]; // Yüklenen dosyayı alın
    // Dosya uzantısını kontrol etme
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      // Uzantı JPEG veya PNG değilse, işlemi durdur
      myToast({ status: "error", message: texts.selectBackgroundImageError });
      return;
    }

    // Resmin oranını kontrol etme
    const checkAspectRatioResult = await checkAspectRatio(file);
    if (!checkAspectRatioResult) {
      myToast({ status: "error", message: texts.aspectRatioError });
      return;
    }

    // Resmi base64'e çevirme işlemi
    setImage(file); // Resmi state içerisine atama
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result; // Resmi base64 olarak alın
      setImageData(imageData); // Resmi state içerisine atama
    };
  };

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}:${API_PORT}/user/getAllUser`);
      const data = await response.json();
      setUsers(data);
      setSelectedUser(data[0].userId);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      const imageType = "backgroundimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const SaveBackgroundImageAndRelationUser = async () => {
    setLoading(true);
    try {
      const imageResponse = await uploadImage();
      if (imageResponse && imageResponse.imageId) {
        const ENDPOINT = "ImageUserRelation/saveImageUserRelation";
        const userId = selectedUser.value;
        const imageId = imageResponse.imageId;
        var relation = {
          image: {
            imageId: imageId,
          },
          user: {
            userId: userId,
          },
          imageType: "backgroundimages",
          status: true,
          singleOrMultiple: multipleBackgroundImageState,
        };
        const response = await fetch(`${API_URL}:${API_PORT}/${ENDPOINT}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(relation),
        });
        const data = await response.json();

        if (response.status === 200) {
          myToast({
            status: "success",
            message: texts.backgroundImagesSaveSuccess,
          });
          navigate("/background-images");
        } else {
          myToast({ status: "error", message: texts.backgroundImageSaveError });
        }
      } else {
        myToast({ status: "error", message: texts.backgroundImageSaveError });
        return;
      }
    } catch (error) {
      myToast({ status: "error", message: texts.backgroundImageSaveError });
    }
    setLoading(false);
  };
  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <Card className="w-full h-full shadow-2xl p-4 ">
        {texts.addCategory}
        <div floated={false} shadow={false} className="rounded-none gap-8">
          <div className="relative w-full flex justify-center">
            <img
              className="h-[438px] w-[310px] rounded-xl object-cover object-center shadow-xl"
              src={imageData || "https://placehold.co/2480x3508"}
              alt="categoryImage"
            />
            <input
              type="file"
              id="file-upload"
              accept="image/jpeg, image/png, image/jpg" // Sadece resim dosyalarını seçebilmesi için
              ref={fileInputRef} // useRef ile oluşturulan referans burada atanıyor
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <label
              htmlFor="file-upload"
              className="absolute  font-[Inter] flex items-center justify-center cursor-pointer"
              onClick={triggerFileInput} // Butona tıklandığında triggerFileInput fonksiyonu çağrılıyor
            >
              <Button
                className="w-[310px] rounded-none rounded-t-xl opacity-75 font-[Inter]"
                color="blue"
                size="md"
              >
                {texts.addImage}
              </Button>
            </label>
          </div>
        </div>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col gap-2">
              <Select
                className="w-full h-10 rounded-md px-2"
                value={selectedUser}
                onChange={handleUserChange}
                options={options}
                placeholder={texts.selectUserError}
                isClearable
              />
              <div>
                <Checkbox
                  color="blue"
                  label={texts.multipleBackgroundImages}
                  checked={!multipleBackgroundImageState}
                  onChange={() => setMultipleBackgroundImageState(false)}
                />
                <Checkbox
                  color="blue"
                  label={texts.singleBackgroundImage}
                  checked={multipleBackgroundImageState}
                  onChange={() => setMultipleBackgroundImageState(true)}
                />
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex justify-end">
          <Button
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1 font-[Inter]"
          >
            <span>{texts.backgroundImageCancel}</span>
          </Button>
          <Button
            loading={loading}
            className="font-[Inter]"
            color="green"
            onClick={SaveBackgroundImageAndRelationUser}
          >
            <span>{texts.backgroundImageSave}</span>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
