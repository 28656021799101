import {
  Card,
  Button,
  CardBody,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import React, { useRef, useState, useEffect } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].dealerTexts;
  const [loading, setLoading] = useState(false);
  const [dealer, setDealer] = useState({
    dealerName: "",
    dealerPhone: "",
    dealerAddress: "",
    dealerPostalCode: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDealer = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await fetch(
            `${API_URL}:${API_PORT}/dealer/findById?dealerId=${id}`
          );
          const data = await response.json();
          setDealer(data);
        } catch (error) {
          myToast({ status: "error", message: texts.fetchUserError });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDealer();
  }, [id, texts.fetchUserError]);

  const saveDealer = async () => {
    try {
      const response = await fetch(`${API_URL}:${API_PORT}/dealer/saveDealer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dealer),
      });
      const data = await response.json();
      if (data) {
        myToast({ status: "success", message: texts.dealerSaveSuccess });
        navigate("/dealer");
      } else {
        myToast({ status: "error", message: texts.dealerSaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.dealerSaveError });
    }
  };

  const handleSave = async () => {
    saveDealer();
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <Spinner className="h-40 w-40" />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <h>{texts.addDealer}</h>
          <CardBody className="px-0 flex flex-col gap-2">
            <Input
              value={dealer.dealerName}
              onChange={(e) =>
                setDealer({ ...dealer, dealerName: e.target.value })
              }
              label={texts.dealerTableTitle.dealerName}
            />
            <Input
              value={dealer.dealerPhone}
              onChange={(e) =>
                setDealer({ ...dealer, dealerPhone: e.target.value })
              }
              label={texts.dealerTableTitle.dealerPhone}
            />
            <Input
              value={dealer.dealerAddress}
              onChange={(e) =>
                setDealer({ ...dealer, dealerAddress: e.target.value })
              }
              label={texts.dealerTableTitle.dealerAddress}
            />
            <Input
              value={dealer.dealerPostalCode}
              onChange={(e) =>
                setDealer({ ...dealer, dealerPostalCode: e.target.value })
              }
              label={texts.dealerPostalCode}
            />

            <Button
              color="blue"
              className="w-full font-[Inter]"
              onClick={handleSave}
            >
              {dealer.dealerId ? texts.updateDealer : texts.addDealer}
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Index;
