import React from "react";
import { useLanguage } from "./languageProvider";
import { languages } from "../constants/languages/languages";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  // Sayfa numaralarını 1'den başlatmak için currentPage ve totalPages değerlerini güncelle
  const adjustedCurrentPage = currentPage + 1;
  const adjustedTotalPages = totalPages;

  // Sayfa numaralarını 1'den başlatmak için düzenlenmiş sayfa numaraları oluştur
  const adjustedPageNumbers = Array.from(
    { length: adjustedTotalPages },
    (_, i) => i + 1
  );

  return (
    <div className="flex items-center justify-between  p-4 w-full">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
        className=" border rounded-md shadow-lg px-4 py-2 mr-2 transition-colors duration-300 ease-in-out hover:bg-blue-500 hover:text-white"
      >
        {texts.previous}
      </button>
      <div className="flex items-center gap-2">
        {adjustedPageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber - 1)} // Sayfa numaralarını 0'dan başlatmak için pageNumber - 1 kullan
            className={`border rounded-md px-4 py-2 ${
              adjustedCurrentPage === pageNumber
                ? "bg-blue-500 text-white shadow-lg"
                : "text-blue-500"
            } transition-colors duration-300 ease-in-out shadow-lg hover:bg-blue-500 hover:text-white`}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
        className=" border rounded-md px-4 shadow-lg py-2 ml-2 transition-colors duration-300 ease-in-out hover:bg-blue-500 hover:text-white"
      >
        {texts.next}
      </button>
    </div>
  );
};

export default Pagination;
