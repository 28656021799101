import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  IconButton,
  Tooltip,
  Spinner,
  Button,
} from "@material-tailwind/react";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../components/languageProvider";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import myToast from "../../../components/myToast";
const PriceName = ({ item, currentLanguage }) => {
  const printNames = {
    tr: item.printNameTr,
    de: item.printNameDe,
    fr: item.printNameFr,
    it: item.printNameIt,
    nl: item.printNameNl,
    en: item.printNameEn,
  };

  return (
    <span className="font-bold font-[Inter]">
      {printNames[currentLanguage] || printNames.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [printPrices, setPrintPrices] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const getAllPrintRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/getAllPrint?page=${page}&size=${size}`
      );
      const data = await response.json();
      setPrintPrices(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllPrintRequest();
  }, []);

  const TABLE_HEAD = [
    texts.printPriceTableTitle.printName,
    texts.printPriceTableTitle.printDescription,
    texts.printPriceTableTitle.printPrice,
    "",
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleDeletePrintPrice = async (selectedPrintPrice) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/deletePrint?printId=${selectedPrintPrice.printId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        getAllPrintRequest();
        myToast({
          status: "success",
          message: texts.successMessages.deleteSuccess,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none py-4 flex flex-row justify-between"
        >
          <span className="px-4 py-4 text-[Inter] text-xl ">
            {texts.printerPage}
          </span>
          {
            <div className="flex items-center">
              <Button
                onClick={() => navigate("/print/add-price")}
                color="blue"
                className="w-60 flex items-center font-[Inter] justify-center"
                size="sm"
              >
                {texts.printPriceTableTitle.addPrice}
              </Button>
            </div>
          }
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {printPrices.map((item, index) => {
                    const isLast = index === printPrices.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.printId}>
                        <td className={classes}>
                          <PriceName
                            item={item}
                            currentLanguage={currentLanguage}
                          />
                        </td>
                        <td className={classes}>
                          <span>{item?.printDescription}</span>
                        </td>
                        <td className={classes}>
                          <span>{item?.printPrice} €</span>
                        </td>
                        <td className={classes}>
                          <Tooltip
                            content={texts.printPriceTableTitle.editPrice}
                          >
                            <IconButton
                              variant="text"
                              disabled={deleteLoading}
                              onClick={() =>
                                navigate(`/print/add-price/${item.printId}`)
                              }
                            >
                              <PencilIcon className="h-6 w-6" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            content={texts.printPriceTableTitle.deletePrice}
                          >
                            <IconButton
                              variant="text"
                              disabled={deleteLoading}
                              onClick={() => handleDeletePrintPrice(item)}
                            >
                              <TrashIcon className="h-6 w-6 text-red-600" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
