import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Button,
  Dialog,
} from "@material-tailwind/react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { languages } from "../../../constants/languages/languages";
import { useLanguage } from "../../../components/languageProvider";
import { useParams } from "react-router-dom";
import {
  EyeSlashIcon,
  TrashIcon,
  PlusCircleIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import myToast from "../../../components/myToast";

const getSubCategoryInfoById = async (id) => {
  try {
    const response = await fetch(
      `${API_URL}:${API_PORT}/subCategory/findBySubCategoryId?subCategoryId=${id}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const getImageByCategoryId = async (subCategoryId) => {
  try {
    const response = await fetch(
      `${API_URL}:${API_PORT}/ImageSubCategoryRelation/findSubCategoryById?subCategoryId=${subCategoryId}&page=0&size=10000&sort=createdAt,desc`
    );
    const data = await response.json();
    return data?.content;
  } catch (error) {
    throw error;
  }
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true); // Başlangıçta loader aktif
  const [loadedImages, setLoadedImages] = useState(0); // Yüklenen resim sayısı
  const [totalImages, setTotalImages] = useState(0); // Toplam resim sayısı
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingVisibility, setLoadingVisibility] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch subcategory info
        const subCategoryData = await getSubCategoryInfoById(id);
        const subCategory = subCategoryData;
        const category = subCategoryData?.category;

        setCategory(category);
        setSubCategory(subCategory);

        // Set subcategory and category names based on current language
        if (currentLanguage === "de") {
          setSubCategoryName(subCategory?.subCategoryNameDe);
          setCategoryName(category?.categoryNameDe);
        } else if (currentLanguage === "nl") {
          setSubCategoryName(subCategory?.subCategoryNameNl);
          setCategoryName(category?.categoryNameNl);
        } else if (currentLanguage === "tr") {
          setSubCategoryName(subCategory?.subCategoryNameTr);
          setCategoryName(category?.categoryNameTr);
        } else if (currentLanguage === "fr") {
          setSubCategoryName(subCategory?.subCategoryNameFr);
          setCategoryName(category?.categoryNameFr);
        } else if (currentLanguage === "it") {
          setSubCategoryName(subCategory?.subCategoryNameIt);
          setCategoryName(category?.categoryNameIt);
        } else {
          setSubCategoryName(subCategory?.subCategoryNameEn);
          setCategoryName(category?.categoryNameEn);
        }

        // Fetch images based on subcategory ID
        const imagesData = await getImageByCategoryId(subCategory?.subCategoryId);
        setImages(imagesData);

        // Set total image count
        setTotalImages(imagesData.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, currentLanguage]);

  const handleImageLoad = () => {
    setLoadedImages((prev) => prev + 1);
  };

  useEffect(() => {
    if (loadedImages === totalImages && totalImages > 0) {
      setLoading(false);
    }
  }, [loadedImages, totalImages]);

  const onClickNavigate = () => {
    navigate(
      `/category/addImage?subCategoryId=${subCategory?.subCategoryId}&categoryId=${category?.categoryId}&subCategoryName=${subCategoryName}&categoryName=${categoryName}`
    );
  };

  const handleOpenVisibleImage = async (e, imageId, status) => {
    setLoadingVisibility(true);
    e.preventDefault();
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageSubCategoryRelation/hideSubcategoryImage?imageId=${imageId}&status=${status}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}), // imageId ve status'u body'de gönderiyoruz
        }
      );
      const data = await response.json();
      if (data?.status === status) {
        setImages((prevImages) => {
          return prevImages.map((image) => {
            if (image?.image?.imageId === imageId) {
              return { ...image, status };
            }
            return image;
          });
        });
        myToast({
          status: "success",
          message: texts.successChangingVisibility,
        });
      } else {
        myToast({
          status: "error",
          message: texts.errorChangingVisibility,
        });
      }
    } catch (error) {
      myToast({
        status: "error",
        message: texts.errorChangingVisibility,
      });
    }
    setLoadingVisibility(false);
  };

  // Daha çalışmıyor api gelecek
  const handleDeleteImage = async (image) => {
    try {
      const imageId = image?.image?.imageId;
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageSubCategoryRelation/deleteSubcategoryImage?imageId=${imageId}`,
        {
          method: "DELETE",
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        myToast({
          status: "success",
          message: texts.successDeletingImage,
        });
        setImages((prevImages) => {
          return prevImages.filter(
            (prevImage) => prevImage?.image?.imageId !== imageId
          );
        });
        handleCloseDeleteModal();
      } else {
        myToast({
          status: "error",
          message: texts.errorDeletingImage,
        });
        console.error(
          `Error deleting image: Received status ${response.status}`
        );
      }
    } catch (error) {
      myToast({
        status: "error",
        description: texts.errorDeletingImage,
      });
      console.error("Error deleting image:", error);
    }
  };

  const handleOpenDeleteModal = (image) => {
    setSelectedImage(image);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <Spinner className="h-40 w-40" />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <div className="flex items-center justify-between">
            <span className="text-[#333333] text-lg font-semibold">
              {subCategoryName} - {texts.subCategories}
            </span>
          </div>
          <CardBody className="px-0">
            <div className="w-full flex flex-col justify-center items-center gap-3">
              <span className="text-[#333333] text-2xl">
                {texts.subCategoryImages}
              </span>
              <div className="w-full flex flex-row justify-end gap-4">
                <Button
                  className="w-60 flex items-center font-[Inter] justify-center"
                  color="blue"
                  size="sm"
                  onClick={onClickNavigate}
                >
                  <PlusCircleIcon className="h-5 w-5" />
                  {texts.addImage}
                </Button>
              </div>
              <div className="w-full flex flex-wrap justify-center gap-4 border-t p-4">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="relative group flex flex-col justify-center items-center"
                  >
                    <img
                      src={`${API_URL}:${API_PORT}/${image?.image?.imageName}`}
                      alt={`Image_${index + 1}`}
                      className="w-[280px] object-cover cursor-pointer"
                      onLoad={handleImageLoad} // Resim yüklendiğinde handleImageLoad fonksiyonunu çağır
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {image?.status ? (
                        <button
                          disabled={loadingVisibility}
                          onClick={(e) =>
                            handleOpenVisibleImage(
                              e,
                              image?.image?.imageId,
                              false
                            )
                          }
                          className="bg-white rounded-md p-2 text-black hover:text-green-500 hover:bg-gray-100 mr-2"
                        >
                          <EyeIcon className="w-6 h-6" />
                        </button>
                      ) : (
                        <button
                          disabled={loadingVisibility}
                          onClick={(e) =>
                            handleOpenVisibleImage(
                              e,
                              image?.image?.imageId,
                              true
                            )
                          }
                          className="bg-white rounded-md p-2 text-black hover:text-orange-500 hover:bg-gray-100 mr-2"
                        >
                          <EyeSlashIcon className="w-6 h-6" />
                        </button>
                      )}
                      <button className="bg-white rounded-md p-2 text-black hover:text-red-500 hover:bg-gray-100">
                        <TrashIcon
                          onClick={() => handleOpenDeleteModal(image)}
                          className="w-6 h-6"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
          <Dialog
            size="sm"
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
          >
            <div className="p-8">
              <span className="font-bold text-center text-lg">
                {texts.Areyousurewanttodeletethisimage}
              </span>
              <div className="flex justify-center gap-4 mt-6">
                <Button
                  onClick={handleCloseDeleteModal}
                  color="red"
                  className="w-24"
                >
                  {texts.no}
                </Button>
                <Button
                  onClick={() => {
                    handleDeleteImage(selectedImage);
                    handleCloseDeleteModal();
                  }}
                  color="green"
                  className="w-24"
                >
                  {texts.yes}
                </Button>
              </div>
            </div>
          </Dialog>
        </Card>
      )}
    </>
  );
};

export default Index;
