import {
  PlusCircleIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  CardHeader,
  Dialog,
  DialogFooter,
  DialogBody,
} from "@material-tailwind/react";
import { languages } from "../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../components/languageProvider";
import Pagination from "../../components/Pagination";
import myToast from "../../components/myToast";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const size = 10;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userDto, setUserDto] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  // getAllBackgroundImagesByUserId fonksiyonu
  const getAllBackgroundImagesByUserId = async (userId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/findImageByUserIdAndImageType?userId=${userId}&imageType=backgroundimages`
      );
      const data = await response.json();
      if (data?.content?.length > 0) {
        return data?.content;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // getAllUser fonksiyonu
  const getAllUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/getAllUser?page=${page}&size=${size}`
      );
      const data = await response.json();

      // Kullanıcı verilerini işleyin
      const usersWithImages = await Promise.all(
        data.map(async (user) => {
          const images = await getAllBackgroundImagesByUserId(user.userId);
          if (images.length > 0) {
            return { ...user, images }; // User ve arkaplan resimlerini birleştirin
          } else {
            return null;
          }
        })
      );

      // Null olmayanları filtreleyin
      const filteredUsersWithImages = usersWithImages.filter(
        (user) => user !== null
      );
      setUserDto(filteredUsersWithImages);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllUser();
  }, [page]);

  const TABLE_HEAD = [
    texts.userTableTitle.userName,
    texts.userTableTitle.userBackgrondImages,
  ];
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleOpenDeleteDialog = (image) => {
    setDeleteDialog(true);
    setSelectedBackgroundImage(image?.image);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  };
  const handelDeleteBackgroundImage = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/deleteUserBackgroundImages?imageId=${selectedBackgroundImage?.imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      if (response.status === 200) {
        myToast({
          status: "success",
          message: texts.addBackgroundImages.deleteBackgroundImageSuccess,
        });
        getAllUser();
      } else {
        myToast({
          status: "error",
          message: texts.addBackgroundImages.deleteBackgroundImageError,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
    setDeleteDialog(false);
  };
  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4 ">
        <span className="px-4 py-4 text-[Inter] text-xl ">
          {texts.userBackgrondImages}
        </span>
        <CardHeader floated={false} shadow={false} className="rounded-none ">
          <div className="flex flex-col justify-between md:flex-row md:items-center py-2 gap-4">
            <Button
              className="w-60 flex items-center font-[Inter] justify-center"
              color="blue"
              size="sm"
              onClick={() => {
                navigate("/background-images/add");
              }}
            >
              <PlusCircleIcon className="h-5 w-5" />
              {texts.addBackgroundImages.addBackgroundImage}
            </Button>
            <Input
              label={texts.search}
              className="font-[Inter]"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            />
          </div>
        </CardHeader>
        <CardBody className="px-0">
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <Spinner className="h-40 w-40" />
            </div>
          ) : (
            <div className="flex overflow-x-auto items-center justify-between py-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <span className="font-normal font-[Inter] leading-none opacity-70">
                          {head}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {userDto.map((item, index) => {
                    const isLast = index === userDto.length - 1;
                    const classes = isLast
                      ? "p-4 font-bold font-[Inter]"
                      : "p-4 border-b border-blue-gray-50 font-bold font-[Inter]";

                    return (
                      <tr key={item.userId}>
                        <td className={classes}>
                          <span>
                            {item?.userName + " " + item?.userSurname} <br />
                            {item?.userFirmName}
                          </span>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-row w-full gap-4">
                            {item?.images?.map((image, index) => (
                              <div
                                key={index}
                                className="relative group flex justify-center items-center"
                              >
                                <img
                                  className="h-[105px] w-[75px] object-cover rounded-md"
                                  src={`${API_URL}:${API_PORT}/${image?.image?.imageName}`}
                                  alt={`Image_${index + 1}`}
                                />
                                <button
                                  onClick={() => handleOpenDeleteDialog(image)}
                                  className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                >
                                  <TrashIcon className="h-5 w-5 text-red-300 hover:text-red-700" />
                                </button>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardFooter>
      </Card>
      <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}>
        <div className="p-8">
          <DialogBody className="flex flex-col justify-center items-center">
            <img
              src={
                API_URL +
                ":" +
                API_PORT +
                "/" +
                selectedBackgroundImage?.imageName
              }
              className="w-64"
              alt="Selected Image"
            />
            <div className="w-full flex flex-col items-start justify-center gap-4 mt-2 font-[Inter] font-semibold">
              <span>{texts.addBackgroundImages.deleteImageWarning}</span>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              color="blue"
              variant="outlined"
              className="mr-2 font-[Inter]"
              onClick={() => {
                handleCloseDeleteDialog();
              }}
            >
              {texts.no}
            </Button>
            <Button
              color="red"
              variant="filled"
              loading={deleteLoading}
              className="font-[Inter]"
              onClick={() => {
                handelDeleteBackgroundImage();
              }}
            >
              {texts.yes}
            </Button>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};

export default Index;
