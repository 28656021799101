import "./App.css";
import Main from "./pages/Main";
import React from "react";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="w-full h-screen font-[Inter] flex justify-start items-start ">
      <ToastContainer />
      <Main />
    </div>
  );
}

export default App;
