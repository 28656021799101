import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogBody,
  Spinner,
} from "@material-tailwind/react";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { languages } from "../../constants/languages/languages";
import { useLanguage } from "../../components/languageProvider";
import { useParams } from "react-router-dom";
import {
  EyeIcon,
  TrashIcon,
  PencilIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { IconButton, Tooltip, Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import myToast from "../../components/myToast";
import Pagination from "../../components/Pagination";

const SubCategoryName = ({ item, currentLanguage }) => {
  const subCategoryName = {
    tr: item.subCategoryNameTr,
    de: item.subCategoryNameDe,
    fr: item.subCategoryNameFr,
    it: item.subCategoryNameIt,
    nl: item.subCategoryNameNl,
    en: item.subCategoryNameEn,
  };

  return (
    <span className="font-bold font-[Inter]">
      {subCategoryName[currentLanguage] || subCategoryName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];

  const [mainCategory, setMainCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const size = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const TABLE_HEAD = ["DE", "NL", "EN", "TR", "FR", "IT", ""];

  const getMainCategory = async () => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}//category/getAllCategory?page=0&size=9999`
      );
      const data = await response.json();
      const content = data?.content[0];
      return content;
    } catch (error) {
      throw error;
    }
  };

  const getSubCategoriesByCategoryId = async (
    categoryId,
    currentPage,
    size
  ) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/subCategory/findByLikeCategoryId?categoryId=${categoryId}&page=${currentPage}&size=${size}&sort=subCategoryNameDe,asc`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch category info
        const categoryData = await getMainCategory();
        setMainCategory(categoryData);
        if (currentLanguage === "de") {
          setHeaderText(categoryData?.categoryNameDe);
        } else if (currentLanguage === "nl") {
          setHeaderText(categoryData?.categoryNameNl);
        } else if (currentLanguage === "tr") {
          setHeaderText(categoryData?.categoryNameTr);
        } else if (currentLanguage === "fr") {
          setHeaderText(categoryData?.categoryNameFr);
        } else if (currentLanguage === "it") {
          setHeaderText(categoryData?.categoryNameIt);
        } else {
          setHeaderText(categoryData?.categoryNameEn);
        }

        const subCategoryData = await getSubCategoriesByCategoryId(
          categoryData?.categoryId,
          currentPage,
          size
        );
        setSubCategories(subCategoryData?.content);
        setTotalPages(subCategoryData?.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, currentPage, size, currentLanguage]);

  const handelOpenDeleteModal = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setDeleteModal(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDeleteSubCategory = async (subCategoryId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/subCategory/deleteSubCategory?subCategoryId=${subCategoryId}`,
        {
          method: "DELETE",
        }
      );
      if (response.status === 200) {
        setDeleteModal(false);
        const newSubCategories = subCategories.filter(
          (item) => item.subCategoryId !== subCategoryId
        );
        setSubCategories(newSubCategories);
        myToast({
          status: "success",
          message: texts.addCategoryItems.categoryDeleteSuccess,
        });
      } else {
        myToast({
          status: "error",
          message: texts.addCategoryItems.categoryDeleteError,
        });
      }
      setDeleteModal(false);
    } catch (error) {
      myToast({
        status: "error",
        message: texts.addCategoryItems.categoryDeleteError,
      });
      setDeleteModal(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <Spinner className="h-40 w-40" />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl py-4">
          <CardBody className="px-0">
            <div className="w-full flex flex-col justify-between items-center gap-3">
              <span className="text-[#333333] text-2xl">{headerText}</span>
              <div className="w-full flex flex-row justify-end gap-4 px-4">
                <Button
                  className="w-60 flex gap-2 items-center font-[Inter] justify-center"
                  color="blue"
                  size="sm"
                  onClick={() => {
                    navigate("/category/addCategory");
                  }}
                >
                  <PlusCircleIcon className="h-5 w-5" />
                  {texts.addCategory}
                </Button>
              </div>
              <div className="w-full overflow-x-auto flex flex-wrap justify-center gap-4 border-t ">
                {
                  <table className="w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={`head_${index}`} // Başlık anahtarı oluşturuyoruz
                            className="justify-center border-blue-gray-100 bg-blue-gray-50/50 p-4"
                          >
                            <span className="text-sm  font-[Inter] leading-none opacity-70">
                              {head}
                            </span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {subCategories.map((item, index) => {
                        const isLast = index === subCategories.length - 1;
                        const classes = isLast
                          ? "p-4 font-[Inter]"
                          : "p-4 border-b font-[Inter] border-blue-gray-50";
                        const spanClasses = "text-xs font-bold font-[Inter]";
                        return (
                          <tr key={item.categoryId}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <span color="blue-gray" className={spanClasses}>
                                  {item.subCategoryNameDe}
                                </span>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <span color="blue-gray" className={spanClasses}>
                                  {item.subCategoryNameNl}
                                </span>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <span color="blue-gray" className={spanClasses}>
                                  {item.subCategoryNameEn}
                                </span>
                              </div>
                            </td>

                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <span color="blue-gray" className={spanClasses}>
                                  {item.subCategoryNameTr}
                                </span>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <span color="blue-gray" className={spanClasses}>
                                  {item.subCategoryNameFr}
                                </span>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <span color="blue-gray" className={spanClasses}>
                                  {item.subCategoryNameIt}
                                </span>
                              </div>
                            </td>
                            <td className={classes}>
                              <Tooltip content={texts.reviewCategory}>
                                <IconButton
                                  variant="text"
                                  color="blue"
                                  onClick={() => {
                                    navigate(
                                      `/category/reviewSubCategory/${item.subCategoryId}`
                                    );
                                  }}
                                >
                                  <EyeIcon className="h-7 w-7" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip content={texts.editCategory}>
                                <IconButton
                                  variant="text"
                                  color="blue"
                                  onClick={() => {
                                    navigate(
                                      `/category/editCategory/${item.subCategoryId}`
                                    );
                                  }}
                                >
                                  <PencilIcon className="h-7 w-7" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip content={texts.deleteCategory}>
                                <IconButton
                                  variant="text"
                                  color="red"
                                  onClick={() => {
                                    handelOpenDeleteModal(item);
                                  }}
                                >
                                  <TrashIcon className="h-7 w-7" />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </CardFooter>
          <Dialog
            size="sm"
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
          >
            <DialogHeader>
              <SubCategoryName
                item={selectedSubCategory}
                currentLanguage={currentLanguage}
              />
            </DialogHeader>
            <DialogBody>
              <span className="text-lg font-bold">
                {texts.addCategoryItems.subCategoryDeleteInfo}
              </span>
            </DialogBody>
            <DialogFooter>
              <Button
                color="blue"
                className="mr-2 font-[Inter]"
                onClick={() => setDeleteModal(false)}
              >
                {texts.addCategoryItems.deleteSubCategoryCancel}
              </Button>
              <Button
                color="red"
                className="font-[Inter]"
                onClick={() => {
                  handleDeleteSubCategory(selectedSubCategory.subCategoryId);
                }}
              >
                {texts.addCategoryItems.deleteSubCategory}
              </Button>
            </DialogFooter>
          </Dialog>
        </Card>
      )}
    </>
  );
};

export default Index;
